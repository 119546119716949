import {
  CO2EconomyItem,
  CO2EconomyItemsResponse,
  CO2EconomyLink,
  CO2EmissionSourceGroupDescription,
  CO2PlanMeta,
  CO2PlanMetaCategoryType,
  CO2PlanMetaStatsDto,
  MetaQuestions,
} from "../../pages/ClimatePlan/shared/CO2Plan";
import ApiService from "../api.service";
import { CO2Plan, CO2SponsorSolution, CO2UnitAddress } from "../../pages/ClimatePlan/shared/CO2Plan";
import { SearchResult } from "../../definitions/model/SearchResult";
import { CO2HeatingPrice } from "../../definitions/HeatingPrices";
import { CO2SponsorSolutionsResponse } from "../../definitions/Company";

const CO2PlanAdminService = {
  getPlanAsAdmin: async (unitId: string, companyId?: string): Promise<CO2Plan> => {
    let url = `/api/admin/co2/plans/${unitId}`;
    if (companyId) url += `/companies/${companyId}`;

    const result = await ApiService.get<CO2Plan>(url);
    return result;
  },
  getUnitsWithCO2Plans: async (
    skip: number,
    take: number,
    communes: Array<number>,
    address: string,
    orderBy: string,
    desc: boolean,
    company?: string
  ): Promise<SearchResult<CO2UnitAddress[]>> => {
    const companyId = company ? `&company=${company}` : "";
    const communesParams = communes.map((t) => "&communes=" + t).join("");
    const url = `/api/admin/co2/plans/?skip=${skip}&take=${take}&address=${address}&orderBy=${orderBy}&desc=${desc}${communesParams}${companyId}`;
    const result = await ApiService.get<SearchResult<CO2UnitAddress[]>>(url);
    return result;
  },
  getSponsorSolutions: async (skip: number, take: number): Promise<CO2SponsorSolutionsResponse> => {
    const url = `/api/admin/co2/sponsor-solutions?skip=${skip}&take=${take}`;
    const result = await ApiService.get<CO2SponsorSolutionsResponse>(url);
    return result;
  },
  createSponsorSolution: async (
    companyId: string,
    emissionSourceGroupId: string,
    emissionSourceChildGroups: string[],
    displayName: string,
    CO2Emission: number,
    widgetId: string | null
  ): Promise<CO2SponsorSolution> => {
    const url = `/api/admin/co2/sponsor-solutions`;
    const data = {
      companyId,
      emissionSourceGroupId,
      emissionSourceChildGroups,
      displayName,
      CO2Emission,
      widgetId,
    };
    const result = await ApiService.post<CO2SponsorSolution>(url, data);
    return result;
  },
  editSponsorSolution: async (
    id: string,
    companyId: string,
    emissionSourceGroupId: string,
    emissionSourceChildGroups: string[],
    displayName: string,
    CO2Emission: number,
    widgetId: string | null
  ): Promise<CO2SponsorSolution> => {
    const url = `/api/admin/co2/sponsor-solutions`;
    const data = {
      id,
      companyId,
      emissionSourceGroupId,
      emissionSourceChildGroups,
      displayName,
      CO2Emission,
      widgetId,
    };
    const result = await ApiService.put<CO2SponsorSolution>(url, data);
    return result;
  },
  deleteSponsorSolution: async (id: string): Promise<string> => {
    const url = `/api/admin/co2/plans/${id}`;
    const result = await ApiService.delete<string>(url);
    return result;
  },
  getCO2EconomyItems: async (skip: number, take: number): Promise<CO2EconomyItemsResponse> => {
    const url = `/api/admin/co2/economy-items?skip=${skip}&take=${take}`;
    const result = await ApiService.get<CO2EconomyItemsResponse>(url);
    return result;
  },
  createCO2EconomyItem: async (
    emissionSourceGroupId: string,
    displayName: string,
    externalLink: string,
    hideCommunes: Array<number>,
    showCommunes: Array<number>,
    links: Array<CO2EconomyLink>
  ): Promise<CO2EconomyItem> => {
    const url = `/api/admin/co2/economy-items`;
    const data = {
      EmissionSourceGroupId: emissionSourceGroupId,
      DisplayName: displayName,
      ExternalLink: externalLink,
      HideCommunes: hideCommunes,
      ShowCommunes: showCommunes,
      Links: links,
    };
    const result = await ApiService.post<CO2EconomyItem>(url, data);
    return result;
  },
  editCO2EconomyItem: async (
    id: string,
    emissionSourceGroupId: string,
    displayName: string,
    externalLink: string,
    hideCommunes: Array<number>,
    showCommunes: Array<number>,
    links: Array<CO2EconomyLink>
  ): Promise<CO2EconomyItem> => {
    const url = `/api/admin/co2/economy-items`;
    const data = {
      Id: id,
      EmissionSourceGroupId: emissionSourceGroupId,
      DisplayName: displayName,
      ExternalLink: externalLink,
      HideCommunes: hideCommunes,
      ShowCommunes: showCommunes,
      Links: links,
    };
    const result = await ApiService.put<CO2EconomyItem>(url, data);
    return result;
  },
  deleteCO2EconomyItem: async (id: string): Promise<string> => {
    const url = `/api/admin/co2/economy-items/${id}`;
    const result = await ApiService.delete<string>(url);
    return result;
  },
  getAgeGroupedItems: async (): Promise<Record<string, number>[]> => {
    const url = `/api/admin/co2/plan-ages`;
    const result = await ApiService.get<Record<string, number>[]>(url);
    return result;
  },
  getHeatingPrice: async (): Promise<CO2HeatingPrice[][]> => {
    const url = "api/CO2plan/heating-prices";
    const result = await ApiService.get<CO2HeatingPrice[][]>(url);
    return result;
  },

  getCO2PlanMetaItems: async (skip: number, take: number) => {
    const url = `/api/admin/co2/meta?filter=&skip=${skip}&take=${take}`;
    const result = await ApiService.get<SearchResult<CO2PlanMeta>>(url);
    return result;
  },
  getCO2PlanMetaStats: async (metaId: string): Promise<CO2PlanMetaStatsDto> => {
    const url = `/api/admin/co2/meta/${metaId}/stats`;
    const result = await ApiService.get<CO2PlanMetaStatsDto>(url);
    return result;
  },
  createCO2PlanMetaItem: async (
    displayName: string,
    initialSurvey: boolean,
    targetSurvey: boolean,
    mainToolBar: boolean,
    companies: Array<string>,
    communes: Array<number>,
    questions: Array<MetaQuestions>,
    targetCategory: CO2PlanMetaCategoryType,
    placeAfterSourceId: string
  ): Promise<CO2PlanMeta> => {
    const url = `/api/admin/co2/meta`;
    const data = {
      DisplayName: displayName,
      InitialSurvey: initialSurvey,
      TargetSurvey: targetSurvey,
      MainToolBar: mainToolBar,
      Companies: companies,
      Communes: communes,
      Questions: questions,
      TargetCategory: targetCategory,
      PlaceAfterSourceId: placeAfterSourceId,
    };

    const result = await ApiService.post<CO2PlanMeta>(url, data);
    return result;
  },
  editCO2PlanMetaItem: async (
    id: string,
    displayName: string,
    initialSurvey: boolean,
    targetSurvey: boolean,
    mainToolBar: boolean,
    companies: Array<string>,
    communes: Array<number>,
    questions: Array<MetaQuestions>,
    targetCategory: CO2PlanMetaCategoryType,
    placeAfterSourceId: string
  ): Promise<CO2PlanMeta> => {
    const url = `/api/admin/co2/meta`;
    const data = {
      Id: id,
      DisplayName: displayName,
      InitialSurvey: initialSurvey,
      TargetSurvey: targetSurvey,
      MainToolBar: mainToolBar,
      Companies: companies,
      Communes: communes,
      Questions: questions,
      TargetCategory: targetCategory,
      PlaceAfterSourceId: placeAfterSourceId,
    };
    const result = await ApiService.put<CO2PlanMeta>(url, data);
    return result;
  },
  deleteCO2PlanMetaItem: async (id: string): Promise<string> => {
    const url = `/api/admin/co2/meta/${id}`;
    const result = await ApiService.delete<string>(url);
    return result;
  },
  getEmissionGroups: async (): Promise<CO2EmissionSourceGroupDescription[]> => {
    const url = `/api/admin/co2/emission-groups`;
    const result = await ApiService.get<CO2EmissionSourceGroupDescription[]>(url);
    return result;
  },
};

export default CO2PlanAdminService;
