import React from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EditTenderForm from "./components/EditTenderForm";
import ContractorsComponent from "./components/ContractorsComponent";
import { useBuilding } from "../../../actions";
import Folders from "../../House/Documents/Folders";
import { DocumentSourceType } from "../../../definitions/Document";
import { useServicebook } from "../../../actions/servicebook.actions";
import QualityParameters from "./components/QualityParameters";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const StyledTabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box mt={1} width="100%" role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Grid container justifyContent="center">
          {children}
        </Grid>
      )}
    </Box>
  );
};

const EditTender = () => {
  const { addressURL, tenderId } = useParams<{ addressURL: string; tenderId: string }>();
  const { t } = useTranslation("translation");
  const [currentTab, setCurrentTab] = React.useState(0);
  const [loadingContractors, setLoadingContractors] = React.useState(false);

  const [{ building, loading }, buidingActions] = useBuilding();
  const [, { getTenderContractors }] = useServicebook();

  React.useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (!loading && building?.Id !== id) {
        buidingActions.getBuilding(id);
      }
    }
  }, [addressURL]);

  React.useEffect(() => {
    const getAsync = async () => {
      try {
        setLoadingContractors(true);
        if (tenderId) {
          await getTenderContractors(tenderId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingContractors(false);
      }
    };

    getAsync();
  }, []);

  const handleChange = (event: React.ChangeEvent<any>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={6} sm={9} xs={12}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{ width: "100%" }}
        >
          <Tab label={t("ServiceBook.Tender.TenderDetails")} />
          <Tab label={t("ServiceBook.Tender.Contractors")} />
          <Tab label={t("ServiceBook.Tender.QualityParameters")} />
          <Tab label={t("Building.Documents")} />
        </Tabs>
      </Grid>
      <Grid item container>
        <StyledTabPanel value={currentTab} index={0}>
          <EditTenderForm showContractors={() => setCurrentTab(1)} />
        </StyledTabPanel>
        <StyledTabPanel value={currentTab} index={1}>
          {building && tenderId && (
            <ContractorsComponent loading={loadingContractors} building={building} tenderId={tenderId} />
          )}
        </StyledTabPanel>
        <StyledTabPanel value={currentTab} index={2}>
          {tenderId && <QualityParameters tenderId={tenderId} />}
        </StyledTabPanel>
        <StyledTabPanel value={currentTab} index={3}>
          {building && tenderId && (
            <Folders
              parentSourceId={building.Id}
              sourceItemId={tenderId}
              sourceType={DocumentSourceType.Tender}
              usePermissions={false}
            />
          )}
        </StyledTabPanel>
      </Grid>
    </Grid>
  );
};

export default EditTender;
