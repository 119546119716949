import React, { useState, useCallback, Dispatch, SetStateAction, useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { GroupMemberRole } from "../constants/enums";
import { Theme } from "@mui/material/styles";
import { theme, communeTheme, commercialTheme } from "../constants/theme";
import { useLocation } from "react-router-dom";
import NavigationHelper from "./navigation-helper";

export const useCO2State = () => useSelector((state: ApplicationState) => state.co2);

export const useUserTheme = (preferableTheme?: string): Theme =>
  useSelector((state: ApplicationState) => {
    const getThemeByName = (themeName: string) => {
      switch (themeName) {
        case "communeTheme":
          return communeTheme;
        case "commercialTheme":
          return commercialTheme;
        default:
          return theme;
      }
    };
    if (state.user.themeName) return getThemeByName(state.user.themeName);
    if (state.user.user?.IsCommuneUser && NavigationHelper.IsClimateSite()) return communeTheme;
    if (preferableTheme) return getThemeByName(preferableTheme);
    if (NavigationHelper.IsClimateSite()) return commercialTheme;
    return theme;
  });

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const useInfiniteScroll = <TElement extends Element>(
  ref: React.MutableRefObject<TElement | null>,
  scrollCallback: () => unknown,
  delay = 300
): void => {
  function handleScroll(this: TElement) {
    const { clientHeight, scrollTop, scrollHeight } = this;
    if (clientHeight + scrollTop >= scrollHeight) {
      scrollCallback();
    }
  }

  useEffect(() => {
    const handler = _.debounce(handleScroll, delay);
    const current = ref && ref.current ? ref.current : null;

    if (current) {
      current.addEventListener("scroll", handler);
      if (current.clientHeight === current.scrollHeight) {
        scrollCallback();
      }
    }

    return () => {
      if (current) {
        current.removeEventListener("scroll", handler);
      }
    };
  }, [scrollCallback, handleScroll, ref]);
};

export const useWindowInfiniteScroll = (scrollCallback: () => void, delay = 300): void => {
  function handleScroll(this: Window) {
    const { clientHeight, scrollTop, scrollHeight } = this.document.documentElement;
    if (clientHeight + scrollTop >= scrollHeight) {
      scrollCallback();
    }
  }

  useEffect(() => {
    const handler = _.debounce(handleScroll, delay);
    const { clientHeight, scrollHeight } = document.documentElement;
    window.addEventListener("scroll", handler);
    if (clientHeight === scrollHeight) {
      scrollCallback();
    }

    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, [scrollCallback, handleScroll]);
};

export const useToggler = (
  initialState: boolean,
  sideEffect?: (state: boolean) => void
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(!state), [state]);
  if (sideEffect) sideEffect(state);

  return [state, toggle, setState];
};

const defaultReducer =
  <T>() =>
  (state: T, action: { type: keyof T; payload: unknown }): T => {
    return { ...state, [action.type]: action.payload };
  };

export const useDefaultReducer = <T>(initialState: T): [T, React.Dispatch<{ type: keyof T; payload: unknown }>] => {
  return useReducer(defaultReducer<T>(), initialState);
};

export const useTranslatedGroupRoles = () => {
  const { t } = useTranslation("translation");

  const getDisplayGroupRole = (role: GroupMemberRole): string => {
    switch (role) {
      case GroupMemberRole.Administrator:
        return t("Family.Roles.Administrator");
      case GroupMemberRole.Member:
        return t("Family.Roles.Member");
      case GroupMemberRole.Expert:
        return t("Family.Roles.Expert");
      case GroupMemberRole.InvitedUser:
        return t("Family.Roles.InvitedMember");
      case GroupMemberRole.Owner:
        return t("Family.Roles.Owner");
      case GroupMemberRole.Manager:
        return t("Family.Roles.Manager");
      case GroupMemberRole.Caretacker:
        return t("Family.Roles.Caretacker");
      case GroupMemberRole.Architect:
        return t("Family.Roles.Architect");
      case GroupMemberRole.NotValidMember:
        return t("Family.Roles.NotValidMember");
      default:
        return t("Family.Roles.Undefined");
    }
  };

  return { getDisplayGroupRole };
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const _useDoubleClick = (
  ref: React.MutableRefObject<HTMLElement | null>,
  onSingleClick: (ev: globalThis.MouseEvent) => void,
  onDoubleClick: (ev: globalThis.MouseEvent) => void,
  latency = 300
): void => {
  useEffect(() => {
    const clickRef = ref.current;
    let clickCount = 0;
    function handleClick(this: HTMLElement, ev: globalThis.MouseEvent) {
      clickCount += 1;
      setTimeout(() => {
        if (clickCount === 1) onSingleClick(ev);
        else if (clickCount === 2) onDoubleClick(ev);
        clickCount = 0;
      }, latency);
    }

    if (clickRef) {
      // Add event listener for click events
      clickRef.addEventListener("click", handleClick);
    }
    // Remove event listener
    return () => {
      if (clickRef) clickRef.removeEventListener("click", handleClick);
    };
  }, [ref, onDoubleClick, onSingleClick, latency]);
};

export const useDoubleClick = (
  onSingleClick: (ev: React.MouseEvent) => void,
  onDoubleClick: (ev: React.MouseEvent) => void,
  latency = 300
): ((ev: React.MouseEvent) => void) => {
  const clickCount = useRef(0);
  const onClick = (ev: React.MouseEvent) => {
    clickCount.current++;
    setTimeout(() => {
      if (clickCount.current === 1) onSingleClick(ev);
      else if (clickCount.current === 2) onDoubleClick(ev);
      clickCount.current = 0;
    }, latency);
  };

  return onClick;
};

export const useSearchParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
