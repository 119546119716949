import React from "react";
import Folders from "./Folders";
import { useUnit } from "../../../actions/unit.actions";
import { DocumentSourceType } from "../../../definitions/Document";

const DocumentsPage = () => {
  const [{ unit }] = useUnit();
  return (
  <>
        <Folders  sourceItemId={unit?.Id ?? ""} sourceType={DocumentSourceType.Unit} />
        {unit && (
          <Folders
            sourceItemId={unit.BuildingId ?? ""}
            sourceType={DocumentSourceType.Building}
            readonly
            childSourceItemId={unit.Id}
            color="info"
          />
        )}
    </>
  );
};
export default DocumentsPage;
