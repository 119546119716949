import React from "react";
import TenderSectionItems from "./TenderSectionItems";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";
import { EditTenderFormData } from "./EditTenderForm";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import { useTranslation } from "react-i18next";
import { Delete, UnfoldLessDoubleOutlined, UnfoldMoreDoubleOutlined } from "@mui/icons-material";

type TenderSectionsProps = {
  hideCo2?: boolean;
  control: Control<EditTenderFormData, any>;
  register: UseFormRegister<EditTenderFormData>;
  errors: FieldErrors<EditTenderFormData>;
  showContractors: () => void;
};

const TenderSectionsComponent = ({ hideCo2, control, register, errors, showContractors }: TenderSectionsProps) => {
  const { t } = useTranslation("translation");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "Sections",
  });

  return (
    <Card variant="outlined">
      <CardHeader
        title={t("ServiceBook.Tender.Sections")}
        action={
          <Button color="secondary" variant="outlined" onClick={showContractors}>
            {t("ServiceBook.Tender.ConstructionCaseDescription")}
          </Button>
        }
      />
      <CardContent>
        {fields.map((section, sectionIndex) => (
          <Section
            hideCo2={hideCo2}
            key={`section-${sectionIndex}`}
            section={section}
            sectionIndex={sectionIndex}
            control={control}
            register={register}
            errors={errors}
            remove={remove}
          />
        ))}
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            append({
              Id: uuidv4(),
              Name: "",
              Description: "",
              Items: [],
            });
          }}
        >
          {t("ServiceBook.Tender.AddSection")}
        </Button>
      </CardActions>
    </Card>
  );
};

type SectionProps = {
  hideCo2?: boolean;
  section: FieldArrayWithId<EditTenderFormData, "Sections", "id">;
  sectionIndex: number;
  control: Control<EditTenderFormData, any>;
  register: UseFormRegister<EditTenderFormData>;
  errors: FieldErrors<EditTenderFormData>;
  remove: UseFieldArrayRemove;
};

const Section = ({ hideCo2, section, sectionIndex, register, errors, control, remove }: SectionProps) => {
  const { t } = useTranslation("translation");
  const [isExpanded, setIsExpanded] = React.useState(section.Name === "" ? true : false);
  return (
    <Card key={section.id} variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={9}>
            <TextFieldConnector register={register(`Sections.${sectionIndex}.Name`, { required: true })}>
              <TextField
                label={t("ServiceBook.Tender.Name")}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                error={!!errors.Sections?.[sectionIndex]?.Name}
              />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2} alignContent="flex-end">
            <IconButton onClick={() => setIsExpanded(!isExpanded)} color="secondary">
              {isExpanded ? (
                <UnfoldLessDoubleOutlined fontSize="large" />
              ) : (
                <UnfoldMoreDoubleOutlined fontSize="large" />
              )}
            </IconButton>
            <IconButton onClick={() => remove(sectionIndex)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
        <Collapse in={isExpanded}>
          <Grid item xs={12} mt={1}>
            <TextFieldConnector register={register(`Sections.${sectionIndex}.Description`, { required: true })}>
              <TextField
                label={t("ServiceBook.Tender.Description")}
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                variant="outlined"
                error={!!errors.Sections?.[sectionIndex]?.Description}
              />
            </TextFieldConnector>
          </Grid>
          <TenderSectionItems
            control={control}
            register={register}
            errors={errors}
            sectionIndex={sectionIndex}
            hideCo2={hideCo2}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default TenderSectionsComponent;
