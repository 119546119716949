import React from "react";
import {
  Grid,
  List,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Building } from "../../../../definitions/model/Building";
import DetailsSkeleton from "../../../../components/Base/DetailsSkeleton";
import { convertToCurency } from "../../../../services/converter.service";
import { useServicebook } from "../../../../actions/servicebook.actions";
import { getPriseAndCO2 } from "./EditTenderForm";
import { TenderType } from "../../../../definitions/model/ServiceBook";

type ContractorsCasesProps = {
  building: Building;
  tenderId: string;
  loading: boolean;
};

const ContractorsCases = (props: ContractorsCasesProps) => {
  const { t } = useTranslation("translation");
  const [
    {
      tenderData: { tender, contractors },
    },
    serviceBookActions,
  ] = useServicebook();
  const contractorsSections = React.useMemo(
    () =>
    tender?.Sections.map((section) => {
      const price = getPriseAndCO2(tender.Sections, section.Id).price;
      const co2 = getPriseAndCO2(tender.Sections, section.Id).co2;

      const contractorValues = contractors.map((c) => {
        return { price: getRandomSectionValue(price), co2: getRandomSectionValue(co2) };
      });

      return {
        Id: section.Id,
        Name: section.Name,
        price: price,
        co2: co2,
        contractorValues: contractorValues,
      };
      }) ?? [],
    [tender, contractors]
  );

  React.useEffect(() => {
    if (!props.tenderId && tender?.Id !== props.tenderId) serviceBookActions.getTenderDetails(props.tenderId);
  }, [props.tenderId]);

  return (
    <Grid container>
      {props.loading && !contractors.length ? (
        <List disablePadding sx={{ width: "100%" }}>
          {new Array(10).fill(0).map((_, idx) => (
            <DetailsSkeleton key={idx} />
          ))}
        </List>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ "& .MuiTableCell-head": { fontWeight: "bold" } }}>
                <TableCell></TableCell>
                <TableCell align="right">{t("ServiceBook.Tender.Standard")}</TableCell>
                {contractors.map((contractor, index) => (
                  <TableCell key={index} align="right">
                    {contractor.Organization}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contractorsSections.map((section, rowIndex) => (
                <>
                  <StyledTableRow
                    key={rowIndex + "_title"}
                    sx={{
                      "& .MuiTableCell-root": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableCell colSpan={contractors.length + 2}>{section.Name}</TableCell>
                  </StyledTableRow>
                  <TableRow key={rowIndex + "_price"}>
                    <TableCell align="left">{t("ServiceBook.Tender.Price")}</TableCell>
                    <TableCell align="right">{convertToCurency(section.price, ".")}</TableCell>
                    {section.contractorValues.map((c, index) => (
                      <TableCell key={index} align="right">
                        {convertToCurency(c.price, ".")}
                      </TableCell>
                    ))}
                  </TableRow>
                  {tender?.Type !== TenderType.Renovation && (
                    <TableRow key={rowIndex + "_co2"}>
                      <TableCell align="left">
                        <Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2Emission" />
                      </TableCell>
                      <TableCell align="right">{convertToCurency(section.co2, ".")}</TableCell>
                      {section.contractorValues.map((c, index) => (
                        <TableCell key={index} align="right">
                          {convertToCurency(c.co2, ".")}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </>
              ))}
              <StyledTableRow
                sx={{
                  "& .MuiTableCell-root": {
                    fontWeight: "bold",
                  },
                }}
              >
                <TableCell colSpan={contractors.length + 2}>{t("ServiceBook.Tender.Total")}</TableCell>
              </StyledTableRow>
              <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
                <TableCell>{t("ServiceBook.Tender.Price")}</TableCell>
                <TableCell align="right">{convertToCurency(tender?.StandardPrice ?? 0, ".")}</TableCell>
                {contractors.map((contractor, index) => (
                  <TableCell key={index} align="right">
                    {convertToCurency(
                      contractorsSections.reduce((acc, section) => acc + section.contractorValues[index].price, 0),
                      "."
                    )}
                  </TableCell>
                ))}
              </TableRow>
              {tender?.Type !== TenderType.Renovation && (
                <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
                  <TableCell align="left">
                    <Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2Emission" />
                  </TableCell>
                  <TableCell align="right">{convertToCurency(tender?.CO2FootprintKilograms ?? 0, ".")}</TableCell>
                  {contractors.map((contractor, index) => (
                    <TableCell key={index} align="right">
                      {convertToCurency(
                        contractorsSections.reduce((acc, section) => acc + section.contractorValues[index].co2, 0),
                        "."
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": { paddingTop: 8, paddingBottom: 8, backgroundColor: theme.palette.grey.A200 },
}));

function getRandomSectionValue(sectionValue: number) {
  const min = sectionValue - 0.1 * sectionValue;
  const max = sectionValue + 0.1 * sectionValue;

  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}

export default ContractorsCases;
