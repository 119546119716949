import React, { useEffect, useState } from "react";
import {
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  LinearProgress,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { GroupMembersDto, GroupMemberDto, PendingGroupMemberDto } from "../../../definitions/model/Group";
import { GroupMemberRole } from "../../../constants/enums";
import { PersonAdd, PersonRemove } from "@mui/icons-material";
import { useTranslatedGroupRoles } from "../../../helpers/hooks";
import { useAlertContext } from "../../../components/Base";
import { ApplicationState } from "../../../reducers/store";
import { useSelector } from "react-redux";
import { useUser } from "../../../actions/user.actions";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../../../actions";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import ResendIcon from "../../../components/Icons/ResendIcon";
import { InviteTenantDto } from "../../../definitions/model/Building";

type FamilyMemberListProps = {
  members: GroupMemberDto[];
  pendingMembers: PendingGroupMemberDto[];
  selectedMember?: GroupMemberDto;
  buildingId: string;
  memberRoles: GroupMemberRole[];
  listItemClickAction?: (member: GroupMemberDto) => void;
  onAddAction?: (memberId: string, buildingId: string) => Promise<GroupMembersDto>;
  onBlockAction?: (memberId: string, buildingId: string) => Promise<GroupMembersDto>;
  onChangeRoleAction?: (buildingId: string, userId: string, role: GroupMemberRole) => Promise<GroupMembersDto>;
};

const BuildingMembersList = (props: FamilyMemberListProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [{ user }] = useUser();
  const [, buildingActions] = useBuilding();
  const [changedUserId, setChangedUserId] = useState("");
  const userRole = props.members.find((x) => x.UserId === user?.Id)?.Role ?? GroupMemberRole.NotValidMember;
  const isOwner = userRole === GroupMemberRole.Owner;
  const isAdministration =
    userRole === GroupMemberRole.Owner ||
    userRole === GroupMemberRole.Administrator ||
    userRole === GroupMemberRole.Manager ||
    userRole === GroupMemberRole.Caretacker ||
    userRole === GroupMemberRole.Architect;
  const error = useSelector((state: ApplicationState) => state.family.error);
  const { showAlert } = useAlertContext();

  useEffect(() => {
    if (error) showAlert({ text: error, severity: "error" });
  }, [error]);

  const { getDisplayGroupRole } = useTranslatedGroupRoles();

  const onListItemClickHandler = (user: GroupMemberDto) => () => {
    if (props.listItemClickAction) props.listItemClickAction(user);
  };

  const onAddClickHandler = (member: GroupMemberDto) => () => {
    if (props.onAddAction) {
      setChangedUserId(member.UserId);
      props
        .onAddAction(member.UserId, props.buildingId)
        .catch(console.log)
        .finally(() => setChangedUserId(""));
    }
  };

  const onBlockClickHandler = (member: GroupMemberDto) => () => {
    if (props.onBlockAction) {
      setChangedUserId(member.UserId);
      props.onBlockAction(member.UserId, props.buildingId).finally(() => setChangedUserId(""));
    }
  };

  const onChangeRoleActionHandler = (member: GroupMemberDto, newRole: GroupMemberRole) => {
    if (props.onChangeRoleAction) {
      props.onChangeRoleAction(props.buildingId, member.UserId, newRole);
    }
  };

  const onRemoveClickHandler = (member: GroupMemberDto & { Token: string }) => () => {
    setChangedUserId(member.UserId);
    buildingActions
      .removeMember(props.buildingId, member.UserId, member.Token)
      .catch(console.log)
      .finally(() => setChangedUserId(""));
  };

  const onResendPendingUserInviteClick = async (member: PendingGroupMemberDto) => {
    setChangedUserId(member.UserId);
    try {
      await buildingActions.inviteMember(props.buildingId, member.Name, member.Email, member.Role);
    } catch (e: any) {
      console.log(e);
    } finally {
      setChangedUserId("");
    }
  };

  return (
    <List style={{ width: "100%" }}>
      {props.members.map((member) => (
        <React.Fragment key={member.UserId}>
          <ListItem onClick={onListItemClickHandler(member)} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar variant="square" alt={member.Name} src={member.AvatarUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={member.Name}
              secondary={
                <>
                  {!isAdministration && getDisplayGroupRole(member.Role)}
                  {isAdministration && (
                    <Select
                      variant="standard"
                      size="small"
                      disabled={member.Role === GroupMemberRole.Owner}
                      value={member.Role}
                      onChange={(e) => {
                        onChangeRoleActionHandler(member, e.target.value as GroupMemberRole);
                      }}
                    >
                      {props.memberRoles.map((role) => (
                        <MenuItem key={role} value={role} style={{ padding: "10px 20px" }}>
                          {getDisplayGroupRole(role)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </>
              }
            />
            {member.Role === GroupMemberRole.NotValidMember && (
              <ListItemSecondaryAction>
                <>
                  {isAdministration && props.onAddAction && (
                    <Tooltip title="Godkend anmodning">
                      <IconButton onClick={onAddClickHandler(member)} size="large">
                        <PersonAdd />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isAdministration && props.onBlockAction && (
                    <Tooltip title={t("General.Buttons.Reject")}>
                      <IconButton onClick={onBlockClickHandler(member)} size="large">
                        <PersonRemove />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              </ListItemSecondaryAction>
            )}
            {member.UserId !== user?.Id && isOwner && (
              <ListItemSecondaryAction>
                <Tooltip title={t("General.Buttons.Reject")}>
                  <IconButton onClick={onRemoveClickHandler({ ...member, Token: "" })} size="large">
                    <PersonRemove />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          {changedUserId === member.UserId && <LinearProgress />}
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
      {props.pendingMembers.map((member) => (
        <React.Fragment key={member.UserId}>
          <ListItem onClick={onListItemClickHandler(member)} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar variant="square" alt={member.Name} src={member.AvatarUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={`${member.Name}(${member.Email}), ${t("Family.Pending")}: ${new Date(
                member.JoinTime
              ).toLocaleDateString()}`}
              secondary={
                <>
                  {getDisplayGroupRole(member.Role)}
                  {/* {isAdministration && (
                      <Select
                        variant="standard"
                        size="small"
                        disabled={member.Role === GroupMemberRole.Owner}
                        value={member.Role}
                        onChange={(e) => {
                          onChangeRoleActionHandler(member, e.target.value as GroupMemberRole);
                        }}
                      >
                        {props.memberRoles.map((role) => (
                          <MenuItem key={role} value={role} style={{ padding: "10px 20px" }}>
                            {getDisplayGroupRole(role)}
                          </MenuItem>
                        ))}
                      </Select>
                    )} */}
                </>
              }
            />
            {isAdministration && (
              <ListItemSecondaryAction>
                <Stack direction="column">
                  <ConfirmDialog
                    text={t("Building.ResentMemberInviteText")}
                    OkClickHandler={async () => await onResendPendingUserInviteClick(member)}
                  >
                    <Tooltip title={t("Building.ResentMemberInvite")}>
                      <Stack direction="row">
                        <IconButton size="small">
                          <ResendIcon />
                        </IconButton>
                      </Stack>
                    </Tooltip>
                  </ConfirmDialog>
                  <Tooltip title={t("Building.DeleteMember")}>
                    <IconButton onClick={onRemoveClickHandler(member)} size="large">
                      <PersonRemove />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          {changedUserId === member.UserId && <LinearProgress />}
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};

export default BuildingMembersList;
