export const LandingActionTypes = {
  LANDING_OPERATION_FAILED: "LANDING_OPERATION_FAILED",
  GET_WIDGETS: "GET_WIDGETS",
  GET_WIDGETS_SUCCEEDED: "GET_WIDGETS_SUCCEEDED",
  GET_WIDGETS_ADDITIONAL_SUCCEEDED: "GET_WIDGETS_ADDITIONAL_SUCCEEDED",
  GET_USERS_COUNT: "GET_USERS_COUNT",
  GET_USERS_COUNT_SUCCEEDED: "GET_USERS_COUNT_SUCCEEDED",
  ADD_LEAD: "ADD_LEAD",
  ADD_LEAD_SUCCEEDED: "ADD_LEAD_SUCCEEDED",
  GET_LANDING: "GET_LANDING",
  GET_LANDING_SUCCEEDED: "GET_LANDING_SUCCEEDED",
  GET_USER: "GET_LANDING_USER",
  GET_USER_SUCCEEDED: "GET_LANDING_USER_SUCCEEDED",
  GET_USER_SETTIINGS: "GET_LANDING_USER_SETTIINGS",
  GET_USER_SETTIINGS_SUCCEEDED: "GET_LANDING_USER_SETTIINGS_SUCCEEDED",
  UPDATE_USER_SETTIINGS: "UPDATE_LANDING_USER_SETTIINGS",
  UPDATE_USER_SETTIINGS_SUCCEEDED: "UPDATE_LANDING_USER_SETTIINGS_SUCCEEDED",
  ACTIVATE_USER: "ACTIVATE_LANDING_USER",
  ACTIVATE_USER_SUCCEEDED: "ACTIVATE_LANDING_USER_SUCCEEDED",
  GET_CO2_META: "GET_CO2_META",
  GET_CO2_META_SUCCEEDED: "GET_CO2_META_SUCCEEDED",
  SAVE_CO2_META_ANSWERS: "SAVE_CO2_META_ANSWERS",
  SAVE_CO2_META_ANSWERS_SUCCEEDED: "SAVE_CO2_META_ANSWERS_SUCCEEDED",
};
