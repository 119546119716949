import { Reducer } from "react";
import { AppAction } from "../definitions/Action";
import { DocumentActions } from "../constants/document.actiontypes";
import { FolderType, DocumentType, DocumentSourceType } from "../definitions/Document";
import { BuildingActionTypes } from "../constants";
import { NIL } from "uuid";

export interface DocumentStoreState {
  loading: boolean;
  error: string | null;
  rootFolderId: string;
  documentFolders: FolderType[][];
  documentsLoading: string[];
  foldersLoading: string[];
}

export const initialState: DocumentStoreState = {
  loading: false,
  documentsLoading: [],
  foldersLoading: [],
  error: null,
  rootFolderId: NIL,
  documentFolders: [
    //DocumentSourceType.Unit,
    [],
    //DocumentSourceType.Building,
    [],
    //DocumentSourceType.Tender,
    [],
  ],
};

const documentReducer: Reducer<DocumentStoreState, AppAction> = (
  state: DocumentStoreState = initialState,
  action: AppAction
): DocumentStoreState => {
  switch (action.type) {
    case DocumentActions.DELETE_DOCUMENT:
    case DocumentActions.UPLOAD_FILE:
    case DocumentActions.GET_FOLDERS:
    case DocumentActions.CREATE_BASIC_FOLDERS:
      return { ...state, loading: true };
    case DocumentActions.CREATE_FOLDER:
      return {
        ...state,
        loading: true,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType ? [...x, action.payload] : x
        ),
      };
    case DocumentActions.CREATE_DOCUMENT:
      return {
        ...state,
        loading: true,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.FolderId) {
                  const documents = [...folder.Documents, action.payload];
                  return { ...folder, Documents: documents };
                }
                return folder;
              })
            : x
        ),
      };
    case DocumentActions.CREATE_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder) => {
                if (folder.Id === action.payload.FolderId) {
                  return {
                    ...folder,
                    Documents: folder.Documents.filter((document) => {
                      return document.Id !== action.payload.DocumentId;
                    }),
                  };
                }

                return folder;
              })
            : x
        ),
      };
    case DocumentActions.UPDATE_DOCUMENT_FOLDER:
      return {
        ...state,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.Id && folder.CanEdit) {
                  return action.payload;
                }
                return folder;
              })
            : x
        ),
        loading: true,
      };
    case DocumentActions.DELETE_FOLDER:
      return {
        ...state,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.FolderId) {
                  folder.isDirty = true;
                  return folder;
                }
                return folder;
              })
            : x
        ),
      };
    case DocumentActions.UPDATE_DOCUMENT:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.FolderId && folder.Documents.some((x) => x.Id === action.payload.Id)) {
                  return {
                    ...folder,
                    Documents: [...folder.Documents.filter((x) => x.Id !== action.payload.Id), action.payload],
                  };
                }
                if (
                  folder.Id === action.payload.FolderId &&
                  !folder.Documents.some((x) => x.Id === action.payload.Id)
                ) {
                  return {
                    ...folder,
                    Documents: [...folder.Documents, action.payload],
                  };
                }
                if (folder.Documents.some((x) => x.Id === action.payload.Id)) {
                  return {
                    ...folder,
                    Documents: folder.Documents.filter((x) => x.Id !== action.payload.Id),
                  };
                }

                return folder;
              })
            : x
        ),
      };
    case DocumentActions.UPDATE_FILE:
      return {
        ...state,
        documentsLoading: state.documentsLoading ? [...state.documentsLoading, action.payload] : [action.payload],
      };
    case DocumentActions.UPDATE_FILE_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentsLoading: state.documentsLoading.filter((id) => id !== action.payload),
      };
    case DocumentActions.GET_FOLDERS_SUCCEDED:
    case DocumentActions.CREATE_BASIC_FOLDERS_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType ? action.payload.result.Folders : x
        ),
        rootFolderId: action.payload.result.RootFolderId ?? NIL,
      };
    case DocumentActions.REORDER_FOLDERS_SUCCEDED:
      return {
        ...state,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType ? action.payload.result : x
        ),
        loading: false,
      };
    case BuildingActionTypes.GET_BUILDING:
      return {
        ...state,
        documentFolders: state.documentFolders.map((x, i) => (i === DocumentSourceType.Building ? [] : x)),
      };
    case DocumentActions.CREATE_FOLDER_SUCCEDED:
      return {
        ...state,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType ? [...x.filter((x) => x.Id !== action.payload.tempId), action.payload] : x
        ),
        rootFolderId: action.payload.RootFolderId ?? NIL,
        loading: false,
      };
    case DocumentActions.UPDATE_DOCUMENT_FOLDER_SUCCEEDED:
      return {
        ...state,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((f) =>
                f.Id === action.payload.updatedDocumentFolder.Id ? action.payload.updatedDocumentFolder : f
              )
            : x
        ),
        loading: false,
      };
    case DocumentActions.DELETE_FOLDER_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.filter((folder: FolderType) => !action.payload.FolderIds.includes(folder.Id))
            : x
        ),
      };
    case DocumentActions.CREATE_DOCUMENT_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.folderId) {
                  const documents = [
                    ...folder.Documents.filter((x) => x.Id !== action.payload.tempId),
                    action.payload.document,
                  ];
                  return { ...folder, Documents: documents };
                }
                return folder;
              })
            : x
        ),
      };
    case DocumentActions.DELETE_DOCUMENT_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.folderId) {
                  folder.Documents = folder.Documents.filter(
                    (document: DocumentType) => document.Id !== action.payload.documentId
                  );
                  return folder;
                }
                return folder;
              })
            : x
        ),
      };
    case DocumentActions.UPDATE_DOCUMENT_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => {
                if (folder.Id === action.payload.folderId) {
                  const documents = folder.Documents.map((document: DocumentType) => {
                    if (document.Id === action.payload.document.Id) {
                      return action.payload.document;
                    }
                    return document;
                  });
                  return { ...folder, Documents: documents };
                }
                return folder;
              })
            : x
        ),
      };
    case DocumentActions.REORDER_DOCUMENTS:
      return {
        ...state,
        documentsLoading: [...state.documentsLoading, action.payload.DocumentId],
      };
    case DocumentActions.REORDER_DOCUMENTS_SUCCEDED:
      return {
        ...state,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder: FolderType) => (folder.Id === action.payload.Id ? action.payload : folder))
            : x
        ),
        documentsLoading: state.documentsLoading.filter(
          (id) => action.payload.Documents.findIndex((x: DocumentType) => x.Id === id) === -1
        ),
      };
    case DocumentActions.DOCUMENT_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        documentFolders: state.documentFolders.map((x, i) =>
          i === action.payload.SourceType
            ? x.map((folder) => {
                if (!action.payload.document?.id) {
                  return { ...folder, isDirty: false };
                }
                if (folder.Id === action.payload.document?.folderId) {
                  const documents = folder.Documents.map((document) => {
                    if (document.Id === action.payload.document?.id) {
                      return { ...document, isDirty: false };
                    }
                    return document;
                  });
                  return { ...folder, Documents: documents };
                }
                return folder;
              })
            : x
        ),
      };
    default:
      return state;
  }
};

export default documentReducer;
