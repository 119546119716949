import ApiService from "./api.service";
import {
  WidgetModel,
  FormBuilderDTO,
  WidgetMetaModel,
  WidgetCreateModel,
  LandingPageDto,
  WidgetsResponseModel,
  LeadData,
} from "../definitions/Landing";
import { WidgetPage } from "../constants/enums";
import { RegisterLeadContract } from "../definitions/LiteCapaign";
import User, { UserSettingsDTO } from "../definitions/model/User";
import { CompanyModel, CompanyTestimonialModel } from "../definitions/Company";
import { CO2Category, DynamicTranslation, SaveEmissionSource } from "../pages/ClimatePlan/shared/CO2Plan";

const LandingService = {
  getWidgets: async (searchString: string, skip: number, take: number): Promise<WidgetMetaModel[]> => {
    const url = `/api/admin/landing?searchString=${searchString}&skip=${skip}&take=${take}`;
    const result = await ApiService.get<WidgetMetaModel[]>(url);
    return result;
  },
  getWidgetsByPage: async (
    page: WidgetPage,
    skip: number,
    take: number,
    subPage = "",
    unitCommune: number | null,
    languageId?: string
  ): Promise<WidgetsResponseModel> => {
    let url = `/api/Landing/widgets/${page}?skip=${skip}&take=${take}&subPage=${subPage}`;
    if (unitCommune) url += `&unitCommune=${unitCommune}`;
    if (languageId) {
      url += `&languageId=${languageId}`;
    }
    const result = await ApiService.get<WidgetsResponseModel>(url);
    return result;
  },
  getEditor: async (id: string): Promise<FormBuilderDTO> => {
    const url = `/api/admin/Landing/${id}/editor`;
    const result = await ApiService.get<FormBuilderDTO>(url);
    return result;
  },
  getWidget: async (id: string): Promise<WidgetMetaModel> => {
    const url = `/api/admin/landing/${id}`;
    const result = await ApiService.get<WidgetMetaModel>(url);
    return result;
  },
  addWidget: async (widget: WidgetCreateModel): Promise<WidgetModel> => {
    const url = `/api/admin/landing/widgets`;
    const result = await ApiService.post<WidgetModel>(url, widget);
    return result;
  },
  updateWidget: async (widget: WidgetMetaModel): Promise<WidgetMetaModel> => {
    const url = `/api/admin/landing/${widget.Id}`;
    const result = await ApiService.put<WidgetMetaModel>(url, widget);
    return result;
  },
  deleteWidget: async (widgetId: string) => {
    const url = `/api/admin/landing/${widgetId}`;
    const result = ApiService.delete(url);
    return result;
  },
  getCustomersCount: () => {
    const url = "/api/Landing/users-count";
    const result = ApiService.get(url);
    return result;
  },
  addLead: async (data: RegisterLeadContract): Promise<LeadData> => {
    const url = "/api/landing/lead";
    const result = await ApiService.post<LeadData>(url, data);
    return result;
  },
  getLandingPage: async (uniqueName: string, languageId?: string): Promise<LandingPageDto> => {
    let url = `/api/landing/${uniqueName}`;
    if (languageId) {
      url += `?languageId=${languageId}`;
    }
    const result = await ApiService.get<LandingPageDto>(url);
    return result;
  },
  getUserByUniqueName: async (uniqueName: string): Promise<User> => {
    const url = `/api/landing/users/${uniqueName}`;
    const result = await ApiService.get<User>(url);
    return result;
  },
  activateUser: async (userId: string): Promise<User> => {
    const url = `/api/landing/users/${userId}/activate`;
    const result = await ApiService.post<User>(url, null);
    return result;
  },
  getLandingUserSettings: async (userId: string | null): Promise<UserSettingsDTO> => {
    return await ApiService.get<UserSettingsDTO>("/api/landing/settings" + (userId ? `/${userId}` : ""));
  },
  updateLandingUserSettigns: async (
    userId: string,
    notifications: boolean,
    receiveCalls: boolean,
    facebookNotifications: boolean
  ): Promise<UserSettingsDTO> => {
    return await ApiService.put<UserSettingsDTO>(`/api/landing/notifications/${userId}`, {
      ReceiveNotifications: notifications,
      ReceiveCalls: receiveCalls,
      ReceiveFacebookNotifications: facebookNotifications,
    });
  },
  getLatestTerms: async (languageId: string): Promise<LandingPageDto> => {
    const url = `/api/Landing/terms/current/${languageId}`;
    const result = await ApiService.get<LandingPageDto>(url);
    return result;
  },
  getClientCompanies: async (): Promise<CompanyModel[]> => {
    const url = "/api/landing/companies";
    const result = await ApiService.get<CompanyModel[]>(url);
    return result;
  },
  getCompanyTestimonials: async (): Promise<CompanyTestimonialModel[]> => {
    const url = "/api/landing/testimonials";
    const result = await ApiService.get<CompanyTestimonialModel[]>(url);
    return result;
  },
  getCO2Meta: async (metaId: string) => {
    const url = `/api/landing/co2-metas/${metaId}`;
    const result = await ApiService.get<{ Translations: DynamicTranslation[]; CO2CategoryGroup: CO2Category }>(url);
    return result;
  },
  saveCO2MetaAnswers: async (planId: string, customQuestions: SaveEmissionSource[] | null) => {
    const url = "/api/landing/co2-metas";
    const data = {
      PlanId: planId,
      CustomQuestions: customQuestions,
    };

    await ApiService.post<void>(url, data);
  },
};

export default LandingService;

export const signUpClubBenefit = async (email: string): Promise<void> => {
  const url = `/api/landing/club-signup`;
  const result = await ApiService.post<void>(url, { Email: email });
  return result;
};
