import loadMoveInAutocomplete from "./MoveInAutocomplete";
import loadLeadsForm from "./LeadsForm";
import loadUsersCounter from "./UsersCounter";
import loadSmsLink from "./SmsLink";
import loadUserProperty from "./UserProperty";
import loadUnsubscribe from "./Unsubscribe";
import loadSearchAutocompleteForm from "./SearchAutocompleteForm";
import loadUnitAddress from "./UnitAddress";
import loadBackButton from "./BackButton";
import loadEntityField from "./EntityField";
import loadMyhouseLink from "./MyhouseLink";
import loadEmailEntityField from "./EmailEntityField";
import loadHouseDataButton from "./HouseDataButton";
import loadCO2Companies from "./CO2Companies";
import loadCO2PlanMetaQuestions from "./CO2PlanMetaQuestions";
import loadCustomVideoComponent from "./CustomVideoComponent";
import { GrapesEditor, LoadComponentOptions } from "../../defenitions";
import { LandingType } from "../../../../definitions/Landing";

export { MoveInAutocomplete } from "./MoveInAutocomplete";
export { LeadForm } from "./LeadsForm";
export { UsersCounter } from "./UsersCounter";
export { SearchAutocompleteForm } from "./SearchAutocompleteForm";
export { UnitAddressForm } from "./UnitAddress";
export { BackButton } from "./BackButton";
export { EntityField } from "./EntityField";
export { MyhouseLink } from "./MyhouseLink";
export { HouseDataButton } from "./HouseDataButton";
export { CO2Companies } from "./CO2Companies";
export { CO2PlanMetaQuestions } from "./CO2PlanMetaQuestions";
export { domainstring } from './DomainString';
export {VideoComponent} from "./CustomVideoComponent";

// Checks for react component name inside of component wrapper
export const isReactComponent = (
  element: HTMLElement,
  name: string
): boolean => {
  for (let index = 0; index < element.childNodes.length; index++) {
    if ((element.childNodes[index] as any).tagName === name) return true;
  }
  return false;
};

export const loadComponents = function (
  editor: GrapesEditor,
  type: LandingType,
  options: LoadComponentOptions,
  theme?: string
): void {
  switch (type) {
    case LandingType.SMS:
      loadSmsLink(editor, options);
      break;
    case LandingType.Email:
      loadUserProperty(editor, options);
      loadUnsubscribe(editor, options);
      loadMyhouseLink(editor, options);
      loadEmailEntityField(editor, options);
      break;
    case LandingType.Landing:
    case LandingType.Template:
    default:
      loadMoveInAutocomplete(editor, options, theme);
      loadLeadsForm(editor, options, theme);
      loadUsersCounter(editor, options, theme);
      loadSearchAutocompleteForm(editor, options, theme);
      loadBackButton(editor, options, theme);
      loadEntityField(editor, options, theme);
      loadUnitAddress(editor, options, theme);
      loadMyhouseLink(editor, options);
      loadHouseDataButton(editor, options, theme);
      loadCO2Companies(editor, options, theme);
      loadCO2PlanMetaQuestions(editor, options, theme);
      loadCustomVideoComponent(editor, options, theme);
      break;
  }
};
