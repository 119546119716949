import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { TextFieldConnector } from "../../../components/Base/FormConnector";
import { useTranslation } from "react-i18next";
import { CreateFolderRequest, DocumentSourceType } from "../../../definitions/Document";
import { Controller, useForm } from "react-hook-form";
import { PermissionTarget } from "../../../constants/enums";

type createfolderDialogProps = {
  open: boolean;
  rootFolderId: string;
  sourceItemId: string;
  sourceType: DocumentSourceType;
  usePermissions: boolean;
  permissions: PermissionTarget[];
  handleClose: () => void;
  createFolder: (data: CreateFolderRequest) => Promise<void>;
};

type CreateFolderFormData = {
  Name: string;
  Description: string;
  Permission: PermissionTarget;
};

const CreateFolderDialog = (props: createfolderDialogProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleClose = () => {
    props.handleClose();
  };

  const submit = async (data: CreateFolderFormData) => {
    await props.createFolder({
      RootFolderId: props.rootFolderId,
      SourceItemId: props.sourceItemId,
      SourceType: props.sourceType,
      ...data,
    });
    props.handleClose();
    reset();
  };

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateFolderFormData>({
    defaultValues: {
      Name: "",
      Description: "",
      Permission: PermissionTarget.Member,
    },
  });

  const { t } = useTranslation("translation");
  return (
    <Dialog open={props.open} onClose={handleClose} fullScreen={isXs}>
      <DialogTitle>{t("Documents.CreateFolder")}</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <Grid container>
            <TextFieldConnector register={register("Name", { required: true })}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                label={t("Documents.WriteYourTitle")}
                error={!!errors.Name}
              />
            </TextFieldConnector>
            <TextFieldConnector register={register("Description", { required: false })}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                label={t("Documents.Description")}
                multiline
                minRows={4}
                sx={{
                  marginTop: 2,
                }}
              />
            </TextFieldConnector>
            {props.usePermissions && (
              <FormControl
                fullWidth
                margin="dense"
                sx={{ marginTop: 2, "& .MuiSelect-select": { paddingTop: 1, paddingBottom: 1 } }}
              >
                <InputLabel id="permission-field-label">{t("Documents.Permission")}</InputLabel>
                <Controller
                  name="Permission"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      variant="outlined"
                      label={t("Documents.Permission")}
                      labelId="permission-field-label"
                      error={!!errors.Permission}
                      onChange={(e) => {
                        setValue("Permission", e.target.value as PermissionTarget);
                      }}
                    >
                      {props.permissions.map((permission) => (
                        <MenuItem key={permission} value={permission} style={{ padding: "10px 20px" }}>
                          {t(`Documents.BuildingPermissions.${PermissionTarget[permission]}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  control={control}
                />
              </FormControl>
            )}
          </Grid>
          <Grid container pt={2} justifyContent={isXs ? "center" : "flex-end"} spacing={1}>
            <Grid item pt={1}>
              <Button
                sx={{
                  minWidth: "112px",
                }}
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
              >
                {t("General.Buttons.Create")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  minWidth: "112px",
                }}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={handleClose}
              >
                {t("General.Buttons.Cancel1")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateFolderDialog;
