import { Replay, Send } from "@mui/icons-material";
import React from "react";

const ResendIcon = () => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "Center",
        alignItems: "Center",
        marginLeft: "5px",
      }}
    >
      <Send />
      <Replay sx={{ fontSize: "1rem", right: 0, position: "absolute", bottom: 0 }} />
    </span>
  );
};

export default ResendIcon;
