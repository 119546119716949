import React, { useState } from "react";
import { DocumentSourceType, FolderType } from "../../../../definitions/Document";
import FileDroppable from "../../../../components/Base/Droppable";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  styled,
} from "@mui/material";
import { AdminPanelSettings, ChevronRight, Delete, Diversity3, Edit, LocalPolice, PublicOutlined } from "@mui/icons-material";
import { PermissionTarget } from "../../../../constants/enums";
import Documents from "./Documents";
import FileUploadInput from "../FileUploadInput";
import { DocumentCildSourceItem } from "../Folders";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    background: theme.palette.background.default,
    "&$expanded": {
      margin: 0,
    },
    borderTop: "1px solid  " + theme.palette.divider,
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  "& h5": {
    marginTop: 15,
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  "& form": {
    width: "100%",
  },
}));

const StyledSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== "shouldHighlight" && prop !== "customColor",
})<{ shouldHighlight: boolean; customColor?: string }>(({ theme, shouldHighlight, customColor }) => ({
  "&.MuiAccordionSummary-root": {
    background: theme.palette[customColor ?? "primary"].main,
    alignItems: "flex-start",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginTop: "14px",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    opacity: shouldHighlight ? 0.8 : 1,
    "& p": {
      color: theme.palette[customColor ?? "primary"].contrastText,
    },
    "& svg": {
      color: theme.palette[customColor ?? "primary"].contrastText,
    },
    "&$expanded": {
      minHeight: "auto",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
}));

type FolderProps = {
  folders: FolderType[];
  parentFolderId: string | null;
  readonly: boolean;
  childSourceItemId?: string;
  childSourceItems?: DocumentCildSourceItem[];
  rootFolderId: string;
  sourceItemId: string;
  parentSourceId?: string;
  sourceType: DocumentSourceType;
  usePermissions?: boolean;
  permissionsList: PermissionTarget[];
  processingFolder: string;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit" | undefined;
  dragFolders: boolean;
  expanded: string[];
  setProcessingFolder: React.Dispatch<React.SetStateAction<string>>;
  openDeleteFolderDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, folder: FolderType) => void;
  openFolderEditDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, folder: FolderType) => void;
  uploadDocumentsToFolderAsync: (files: File[], folderId: string, folderPermission: PermissionTarget) => Promise<void>;
  changeExpanded: (folderId: string, expanded: boolean) => void;
  setSettingsRef: (value: boolean) => void;
};

const Folder = (props: FolderProps) => {
  const { folders, openDeleteFolderDialog, openFolderEditDialog } = props;
  const [shouldHighlightId, setShouldHighlightId] = useState("");

  return (
    <>
      {folders
        .filter((f) => f.ParentFolderId === props.parentFolderId)
        .map((folder, idx) => {
          const subfolders = folders.filter((f) => f.ParentFolderId === folder.Id);
          const folderDocuments = folder.Documents.filter(
            (d) =>
              !props.childSourceItemId ||
              d.RelatedSourceIds.includes(props.childSourceItemId) ||
              (props.readonly && d.RelatedSourceIds.length === 0)
          )

          if (props.readonly && subfolders.length === 0 &&  folderDocuments?.length === 0) return null;

          return (
            <>
              <FileDroppable
                key={"folder_" + folder.Id}
                dropAction={async (files) => {
                  try {
                    props.setProcessingFolder(folder.Id);
                    await props.uploadDocumentsToFolderAsync(files, folder.Id, folder.Permission);
                  } finally {
                    props.setProcessingFolder("");
                  }
                }}
                handleDragOver={(dragOver: boolean) => {
                  setShouldHighlightId(dragOver ? folder.Id : "");
                }}
              >
                {/*  */}
                <Droppable key={folder.Id} droppableId={folder.Id}>
                  {(provided, snapshot) => (
                    <Draggable
                      key={"darggabe_folder_" + folder.Id}
                      draggableId={folder.Id}
                      index={idx}
                      isDragDisabled={props.readonly || folder.isDirty}
                    >
                      {(providedFolder, snapshotFolder) => (
                        <Box  ref={provided.innerRef}
                        {...provided.droppableProps}>
                        <StyledAccordion
                         sx={{backgroundColor: "white", transform: snapshot.isDraggingOver ? "unset!important" : ""}}
                          key={folder.Id}
                          square
                              {...providedFolder.draggableProps}                         
                          ref={providedFolder.innerRef}     
                          expanded={!!props.expanded.find((x) => x === folder.Id)}
                          onChange={(event: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
                            props.changeExpanded(folder.Id, expanded)
                          }
                        >
                          <StyledSummary   
                               {...providedFolder.dragHandleProps}                                 
                            expandIcon={<ChevronRight />}
                            shouldHighlight={shouldHighlightId === folder.Id || snapshot.isDraggingOver}
                            customColor={props.color}
                          >
                            <Grid
                              item
                              container
                              direction="column"
                              justifyContent={"flex-start"}
                            >
                              <Grid item>
                                <Typography variant="body1">{folder.Name}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body2">{folder?.Description}</Typography>
                              </Grid>
                            </Grid>
                            {!props.readonly && (
                              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                {folder.isDirty ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <>
                                    {props.usePermissions && (
                                      <IconButton>
                                        {folder.Permission === PermissionTarget.Public && <PublicOutlined />}
                                        {folder.Permission === PermissionTarget.Member && <Diversity3 />}
                                        {folder.Permission === PermissionTarget.Group && <AdminPanelSettings />}
                                        {folder.Permission === PermissionTarget.Owner && <LocalPolice />}
                                      </IconButton>
                                    )}
                                    {!props.childSourceItemId && (
                                      <>
                                        <IconButton onClick={(e) => openFolderEditDialog(e, folder)}>
                                          <Edit />
                                        </IconButton>
                                        <IconButton onClick={(e) => openDeleteFolderDialog(e, folder)}>
                                          <Delete />
                                        </IconButton>
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            )}
                          </StyledSummary>
                          <AccordionDetailsStyled>
                            <Grid container direction="column" sx={{ marginBottom: "20px" }}>
                              {<Folder {...props} folders={folders} parentFolderId={folder.Id} key={folder.Id} />}
                              {folderDocuments?.length ? <Divider /> : null}
                              <Grid container direction="column">
                                {folder && (
                                  <Documents
                                    key={`${folder.Id}_documents`}
                                    readonly={props.readonly ?? false}
                                    rootFolderId={props.rootFolderId}
                                    sourceItemId={props.sourceItemId}
                                    parentSourceId={props.parentSourceId}
                                    sourceType={props.sourceType}
                                    childSourceItemId={props.childSourceItemId}
                                    childSourceItems={props.childSourceItems}
                                    usePermissions={props.usePermissions}
                                    documents={folderDocuments}
                                    folder={folder}
                                    permissionsList={props.permissionsList}
                                    setSettingsRef={props.setSettingsRef}
                                  ></Documents>
                                )}
                              </Grid>
                            </Grid>
                            {!props.readonly && (
                              <FileUploadInput
                                sourceType={props.sourceType}
                                loading={props.processingFolder === folder.Id}
                                uploadFiles={async (files: File[]) => {
                                  await props.uploadDocumentsToFolderAsync(files, folder.Id, folder.Permission);
                                }}
                              />
                            )}
                          </AccordionDetailsStyled>
                          {snapshot.draggingFromThisWith && provided.placeholder}
                        </StyledAccordion>
                        </Box>
                      )}
                    </Draggable>
                  )}
             </Droppable>
                {folder.Id === props.processingFolder && <LinearProgress sx={{ width: "100%" }} color="secondary" />}
              </FileDroppable>
              <Droppable
                key={`folder_${folder.Id}_after`}
                droppableId={`${folder.Id}_after`}
                isDropDisabled={!props.dragFolders}
              >
                {(provided, snapshot) => (
                  <Grid
                    container
                    ref={provided.innerRef}
                    sx={{
                      m: 0.01,
                      p: 0,
                      backgroundColor: snapshot.isDraggingOver ? "lightgrey" : "inherit",
                    }}
                  >
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </>
          );
        })}
    </>
  );
};

export default Folder;
