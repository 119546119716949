import React from "react";
import { Card, CardContent, InputLabel } from "@mui/material";

const LabeldCard = ({ label, children }: { label: string; children: React.ReactNode }) => {
    return (
      <>
        <InputLabel
          shrink
          sx={{
            marginBottom: -2,
            marginLeft: 1,
            paddingLeft: 1,
            paddingRight: 1,
            maxWidth: "fit-content",
            background: "white",
          }}
        >
          {label}
        </InputLabel>
        <Card variant="outlined">
          <CardContent>{children}</CardContent>
        </Card>
      </>
    );
  };

  export default LabeldCard;