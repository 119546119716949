import { Button, CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, DragStart, DropResult, Droppable } from "@hello-pangea/dnd";
import FileDroppable from "../../../../components/Base/Droppable";
import { AddCircleOutline } from "@mui/icons-material";
import FileUploadInput from "../FileUploadInput";
import { CreateFolderRequest, DocumentSourceType, FileType, FolderType } from "../../../../definitions/Document";
import { PermissionTarget } from "../../../../constants/enums";
import { DocumentCildSourceItem } from "../Folders";
import DeleteFolderDialog from "../DeleteFolderDialog";
import { NIL } from "uuid";
import { useAlertContext } from "../../../../components/Base/MyhouseAlert";
import { useDocument } from "../../../../actions/document.actions";
import { useTranslation } from "react-i18next";
import CreateFolderDialog from "../CreateFolderDialog";
import EditDocumentFolderDialog from "../EditDocumentFolderDialog";
import { useUser } from "../../../../actions/user.actions";
import { getFolderType } from "../../../../helpers/document-helpers";
import Folder from "./Folder";

type FolderProps = {
  rootFolderId: string;
  sourceItemId: string;
  sourceType: DocumentSourceType;
  childSourceItemId?: string;
  childSourceItems?: DocumentCildSourceItem[];
  parentSourceId?: string;
  usePermissions?: boolean;
  permissionsList: PermissionTarget[];
  readonly: boolean;
  folders: FolderType[];
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit" | undefined;
};

const FoldersComponent = (props: FolderProps) => {
  const { permissionsList } = props;
  const { showAlert } = useAlertContext();
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [, actions] = useDocument();
  const [userState] = useUser();
  const [folderToDelete, setFolderToDelete] = useState<FolderType | null>(null);
  const [processCreatingFolder, setProcessCreatingFolder] = useState(false);
  const [processingFolder, setProcessingFolder] = useState("");
  const [dragFolders, setDragFolders] = useState(false);
  const [folderToUpdate, setFolderToUpdate] = useState<FolderType | null>(null);
  const [createFolderDialogShown, setCreateFolderDilaogShown] = useState(false);
  const [shouldHighlightOver, setShouldHighlightOver] = useState(false);
  const foldersRef = useRef<FolderType[]>();
  const settingsRef = useRef<{ disablePaste: boolean }>({ disablePaste: false });
  const setSettingsRef = (value: boolean) => {
    settingsRef.current.disablePaste = value;
  };
  const [expanded, setExpanded] = useState<string[]>([]);

  const changeExpanded = (folderId: string, expanded: boolean) => {
    if (expanded) {
      setExpanded((prev) => [...prev, folderId]);
    } else {
      setExpanded((prev) => prev.filter((x) => x !== folderId));
    }
  };

  useEffect(() => {
    if (!navigator.clipboard) {
      return () => {};
    }

    if (!foldersRef.current) {
      foldersRef.current = props.folders;
    }

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    foldersRef.current = props.folders;

    return () => {
      foldersRef.current = undefined;
    };
  }, [props.folders]);

  const getFolderNameByFileTypes = (files: File[]) => {
    const folders = new Map<string, File[]>();

    const updateFolder = (folders: Map<string, File[]>, file: File, folderType: string) => {
      if (folders.has(folderType)) {
        const existingFiles = folders.get(folderType);
        existingFiles?.push(file);
        folders.set(folderType, existingFiles || []);
        return;
      }
      folders.set(folderType, [file]);
    };

    for (let i = 0; i < files.length; i++) {
      const fileType = files[i].type;
      const folderType = getFolderType(fileType);
      updateFolder(folders, files[i], folderType);
    }
    return folders;
  };

  const uploadDocumentsToFolderAsync = async (files: File[], folderId: string, folderPermission: PermissionTarget) => {
    try {
      const uploadAsync = async (file: File) => {
        const fileMetadata = await actions.uploadFile(file);
        if (!fileMetadata) return;
        const doc = {
          RootFolderId: props.rootFolderId,
          SourceItemId: props.sourceItemId,
          SourceType: props.sourceType,
          ChildSourceItemId: props.childSourceItemId,
          ParentSourceId: props.parentSourceId,
          Permission: folderPermission,
          Description: "",
          FileIds: [fileMetadata.Id],
          Name: fileMetadata.Name,
          FolderId: folderId,
        };

        await actions.createDocument(doc);
      };

      setExpanded((prev) => [...prev, folderId]);

      const operations = [];
      for (let i = 0; i < files.length; i++) {
        operations.push(uploadAsync(files[i]));
      }

      await Promise.all(operations);
    } catch (error) {
      showAlert({
        severity: "error",
        text: t("Documents.Messages.OperationError"),
      });
    }
  };

  const createNewFolderAsync = async (folderName: string, files: File[]) => {
    const newFolder = await actions.createFolder({
      RootFolderId: props.rootFolderId,
      SourceItemId: props.sourceItemId,
      SourceType: props.sourceType,
      Permission: PermissionTarget.Member,
      Name: folderName,
      Description: "",
      ParentSourceId: props.parentSourceId,
    });

    if (newFolder === undefined) return;
    await uploadDocumentsToFolderAsync(files, newFolder.Id, newFolder.Permission);
  };

  const createFolderByDraggingFiles = async (files: File[]) => {
    try {
      const operations: Promise<void>[] = [];

      const folders = foldersRef.current;
      if (!folders) return;

      getFolderNameByFileTypes(files).forEach((element, map) => {
        const folder = folders.find((x) => x.Name === t(map));
        if (folder && folder.CanEdit) {
          operations.push(uploadDocumentsToFolderAsync(element, folder.Id, folder.Permission));
          return;
        }

        const folderName = t(map);

        if (
          props.folders.some((folder) => {
            return folder.Name === folderName;
          })
        ) {
          const computedFolderName = `${folderName} (${userState.user?.Name})`;

          operations.push(createNewFolderAsync(computedFolderName, element));
        } else {
          operations.push(createNewFolderAsync(folderName, element));
        }
      });
      if (operations.length === 0) {
        setShouldHighlightOver(false);
        showAlert({
          severity: "error",
          text: t("Documents.Messages.OperationError"),
        });
        return;
      }
      await Promise.all(operations);
      showAlert({
        severity: "success",
        text: t("Documents.Messages.FolderCreated"),
      });
    } catch (e) {
      setShouldHighlightOver(false);
      showAlert({
        severity: "error",
        text: t("Documents.Messages.OperationError"),
      });
    }
  };

  const handlePaste = async (event: Event) => {
    // do not use for readonly folder
    if (props.readonly) {
      return;
    }
    // do not use for input and for components with disablePaste
    if (event) {
      if (settingsRef.current.disablePaste || (event.target as HTMLElement).tagName === "INPUT") {
        return;
      }

      const pasteEvent = event as ClipboardEvent;

      if (pasteEvent.clipboardData) {
        const types = pasteEvent.clipboardData.types;
        //check all possible types
        if (types.includes("Files")) {
          //get the files
          const files = pasteEvent.clipboardData.files;
          await createFolderByDraggingFiles(Array.from(files));
          return;
        }

        const textTypes = ["text/plain", "text/html", "text/rtf"];

        const textType = types.find((type) => textTypes.includes(type));
        if (textType) {
          const text = pasteEvent.clipboardData.getData(textType);
          const file = new File([text], "clipboard.txt", { type: "text/plain" });
          await createFolderByDraggingFiles([file]);
        }
        // Define common image data types
        const imageTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp"];

        // Check if any of the common image types are present on the clipboard
        const imageType = types.find((type) => imageTypes.includes(type));
        if (imageType) {
          const image = pasteEvent.clipboardData.getData(imageType);
          const file = new File([image], "image", { type: imageType });
          await createFolderByDraggingFiles([file]);
        }
      }
    }
  };

  const openDeleteFolderDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, folder: FolderType) => {
    e.preventDefault();
    e.stopPropagation();
    setFolderToDelete(folder);
  };

  const openFolderEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, folder: FolderType) => {
    e.preventDefault();
    e.stopPropagation();
    setFolderToUpdate(folder);
    setSettingsRef(true);
  };

  const deleteFolder = async (folderId: string) => {
    try {
      setFolderToDelete(null);
      await actions.deleteFolder(
        {
          RootFolderId: props.rootFolderId,
          SourceItemId: props.sourceItemId,
          FolderId: folderId,
          ParentSourceId: props.parentSourceId,
        },
        props.sourceType
      );
      showAlert({
        severity: "success",
        text: t("Documents.Messages.FolderRemoved"),
      });
    } catch (e) {
      showAlert({
        severity: "error",
        text: t("Documents.Messages.InsufficientRights"),
      });
    }
  };
  const createFolder = async (data: CreateFolderRequest) => {
    try {
      setProcessCreatingFolder(true);
      setCreateFolderDilaogShown(false);
      await actions.createFolder({
        RootFolderId: props.rootFolderId,
        SourceItemId: props.sourceItemId,
        SourceType: props.sourceType,
        Permission: data.Permission,
        Name: data.Name,
        Description: data.Description,
        ParentSourceId: props.parentSourceId,
      });
      showAlert({
        severity: "success",
        text: t("Documents.Messages.FolderCreated"),
      });
    } finally {
      setProcessCreatingFolder(false);
    }
  };

  const updateFolder = (folderId: string) => async (name: string, description: string, permision: PermissionTarget) => {
    try {
      await actions.updateFolder({
        RootFolderId: props.rootFolderId,
        SourceItemId: props.sourceItemId,
        ParentSourceId: props.parentSourceId,
        SourceType: props.sourceType,
        Permission: permision,
        FolderId: folderId,
        Description: description,
        Name: name,
      });
      showAlert({
        severity: "success",
        text: t("Documents.Messages.FolderUpdated"),
      });
    } catch (e) {
      showAlert({
        severity: "error",
        text: t("Documents.Messages.InsufficientRights"),
      });
    }
  };

  const openCreateFolderDialog = () => {
    setSettingsRef(true);
    setCreateFolderDilaogShown(true);
  };

  const closeEditDialog = () => {
    setSettingsRef(false);
    setFolderToUpdate(null);
  };

  const onDragStart = (start: DragStart) => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }

    setDragFolders(!!props.folders.find((f) => f.Id === start.draggableId));
  };

  const onDragEnd = async (result: DropResult) => {
    const { draggableId, destination, source } = result;
    setDragFolders(false);

    if (!destination) {
      return;
    }

    try {
      if (destination.droppableId === source.droppableId) {
        await actions.reorderDocuments({
          RootFolderId: props.rootFolderId,
          SourceItemId: props.sourceItemId,
          SourceType: props.sourceType,
          FolderId: destination.droppableId,
          DocumentId: draggableId,
          DestinationIndex: destination.index,
        });
        return;
      }

      const destinationFolder = props.folders.find((f) => f.Id === destination.droppableId);
      const draggableFolder = props.folders.find((f) => f.Id === draggableId);
      if (destinationFolder && draggableFolder) {
        setProcessingFolder(draggableId);
        await actions.reorderFolders({
          RootFolderId: props.rootFolderId,
          SourceItemId: props.sourceItemId,
          SourceType: props.sourceType,
          FolderId: draggableId,
          ParentFolderId: destination.droppableId,
          DestinationIndex: 0,
        });
        return;
      }

      if (destination.droppableId.indexOf("_after") !== -1) {
        setProcessingFolder(draggableId);
        const inserAfterId = destination.droppableId.replace("_after", "");
        const folders = props.folders.filter((f) => f.Id !== draggableId);

        const curentIndex = props.folders.findIndex((f) => f.Id === draggableId);
        const newIndex = inserAfterId === NIL ? 0 : folders.findIndex((f) => f.Id === inserAfterId) + 1;
        const folder = props.folders.find((f) => f.Id === draggableId);
        const folderAfter = props.folders.find((f) => f.Id === inserAfterId);
        if (
          (curentIndex === newIndex && folder?.ParentFolderId === folderAfter?.ParentFolderId) ||
          inserAfterId === draggableId
        )
          return;

        await actions.reorderFolders({
          RootFolderId: props.rootFolderId,
          SourceItemId: props.sourceItemId,
          SourceType: props.sourceType,
          FolderId: draggableId,
          ParentFolderId: folderAfter?.ParentFolderId ?? null,
          DestinationIndex: newIndex,
        });
        return;
      }

      const document = props.folders.flatMap((folder) => folder.Documents).find((doc) => doc.Id === draggableId);
      if (!document) return;

      let folderId = destination.droppableId;
      if (folderId === NIL) {
        const folderName = t("Documents.NewFolder") + " " + new Date().toLocaleString("da", { dateStyle: "short" });

        const folder = await actions.createFolder({
          RootFolderId: props.rootFolderId,
          SourceItemId: props.sourceItemId,
          SourceType: props.sourceType,
          Permission: PermissionTarget.Member,
          Name: folderName,
          Description: "",
          ParentSourceId: props.parentSourceId,
        });

        if (folder === undefined) return;
        folderId = folder.Id;
      }

      setExpanded((prev) => [...prev, folderId]);
      await actions.updateDocument({
        RootFolderId: props.rootFolderId,
        SourceItemId: props.sourceItemId,
        SourceType: props.sourceType,
        Permission: document.Permission,
        FolderId: folderId,
        DocumentId: draggableId,
        Description: document.Description,
        Name: document.Name,
        FileIds: document.Files.map((file: FileType) => file.Id),
        RelatedSourceIds: document.RelatedSourceIds,
      });
    } catch (e) {
      showAlert({
        severity: "error",
        text: t("Documents.Messages.OperationError"),
      });
    } finally {
      setProcessingFolder("");
    }
  };

  const closeCreateFolderDialog = () => {
    setSettingsRef(false);
    setCreateFolderDilaogShown(false);
  };

  return (
    <>
      {" "}
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable key={`folder_${NIL}_after`} droppableId={`${NIL}_after`} isDropDisabled={!dragFolders}>
          {(provided, snapshot) => (
            <Grid
              container
              ref={provided.innerRef}
              sx={{
                m: 0.01,
                p: 0,
                backgroundColor: snapshot.isDraggingOver ? "lightgrey" : "inherit",
              }}
            >
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>

        <Folder
          folders={props.folders}
          parentFolderId={null}
          childSourceItemId={props.childSourceItemId}
          rootFolderId={props.rootFolderId}
          sourceItemId={props.sourceItemId}
          parentSourceId={props.parentSourceId}
          sourceType={props.sourceType}
          childSourceItems={props.childSourceItems}
          readonly={props.readonly}
          usePermissions={props.usePermissions}
          permissionsList={permissionsList}
          processingFolder={processingFolder}
          color={props.color}
          dragFolders={dragFolders}
          expanded={expanded}
          openDeleteFolderDialog={openDeleteFolderDialog}
          openFolderEditDialog={openFolderEditDialog}
          uploadDocumentsToFolderAsync={uploadDocumentsToFolderAsync}
          changeExpanded={changeExpanded}
          setSettingsRef={setSettingsRef}
          setProcessingFolder={setProcessingFolder}
        />

        {!props.readonly && (
          <Grid container>
            {!props.childSourceItemId && (
              <Grid item>
                <Button
                  sx={{
                    margin: "16px",
                  }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={openCreateFolderDialog}
                  disabled={processCreatingFolder}
                >
                  {processCreatingFolder ? (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  ) : (
                    <AddCircleOutline />
                  )}
                  {t("Documents.CreateFolder")}
                </Button>
              </Grid>
            )}
            <Grid item sx={{ display: "flex", flex: 1 }}>
              {isXs ? (
                <FileUploadInput sourceType={props.sourceType} uploadFiles={createFolderByDraggingFiles} />
              ) : (
                <FileDroppable
                  dropAction={async (files) => {
                    setProcessingFolder(NIL);
                    try {
                      await createFolderByDraggingFiles(files);
                    } finally {
                      setProcessingFolder("");
                    }
                  }}
                  handleDragOver={(dragOver: boolean) => {
                    setShouldHighlightOver(dragOver);
                  }}
                >
                  <Droppable droppableId={NIL}>
                    {(provided, snapshot) => (
                      <Grid
                        container
                        sx={{
                          m: 1,
                          p: 4,
                          width: "calc(100% - 16px)",
                          border: "1px solid lightgrey",
                          borderRadius: 1,
                          background: shouldHighlightOver || snapshot.isDraggingOver ? "lightGray" : "inherit",
                        }}
                        ref={provided.innerRef}
                      >
                        {provided.placeholder}
                        <FileUploadInput
                          sourceType={props.sourceType}
                          loading={processingFolder === NIL}
                          uploadFiles={createFolderByDraggingFiles}
                        />
                      </Grid>
                    )}
                  </Droppable>
                </FileDroppable>
              )}
            </Grid>
          </Grid>
        )}
      </DragDropContext>
      {!props.readonly && (
        <>
          <CreateFolderDialog
            open={createFolderDialogShown}
            rootFolderId={props.rootFolderId}
            sourceItemId={props.sourceItemId}
            sourceType={props.sourceType}
            permissions={permissionsList}
            usePermissions={props.usePermissions || false}
            handleClose={closeCreateFolderDialog}
            createFolder={createFolder}
          />
          <DeleteFolderDialog
            open={Boolean(folderToDelete)}
            handleClose={() => setFolderToDelete(null)}
            handleDelete={async () => await deleteFolder(folderToDelete?.Id || "")}
            folderName={folderToDelete?.Name || ""}
          />
          {folderToUpdate && (
            <EditDocumentFolderDialog
              open={Boolean(folderToUpdate)}
              description={folderToUpdate.Description}
              permission={folderToUpdate.Permission}
              permissions={permissionsList}
              usePermissions={props.usePermissions || false}
              name={folderToUpdate.Name}
              updateDocumentFolder={updateFolder(folderToUpdate.Id)}
              onClose={closeEditDialog}
            />
          )}
        </>
      )}
    </>
  );
};

export default FoldersComponent;
