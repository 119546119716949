import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FileType,
  DocumentType,
  CreateDocumentRequest,
  UpdateDocumentRequest,
  DocumentSourceType,
} from "../../../../definitions/Document";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import { PermissionTarget } from "../../../../constants/enums";

type DocumentDialogProps = {
  open: boolean;
  rootFolderId: string;
  sourceItemId: string;
  parentSourceId?: string;
  sourceType: DocumentSourceType;
  folderId: string;
  document: DocumentType | null;
  permissions: PermissionTarget[];
  usePermissions: boolean;
  createDocument: (data: CreateDocumentRequest) => void;
  updateDocument: (data: UpdateDocumentRequest) => void;
  uploadFile: (file: File) => Promise<FileType | undefined>;
  handleClose: () => void;
};

type FormData = {
  Name: string;
  Description: string;
  Permission: PermissionTarget;
};

const DocumentDialog = (props: DocumentDialogProps): JSX.Element => {
  const [files, setFiles] = useState<FileType[]>([]);
  const [uploading, setUploading] = useState(false);

  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.document) {
      setFiles(props.document.Files);
      setValue("Name", props.document.Name);
      setValue("Description", props.document.Description);
      setValue("Permission", props.document.Permission);
    } else {
      setFiles([]);
    }

    return () => {
      reset();
    };
  }, [props.document]);

  const submit = (data: FormData) => {
    if (!props.document) {
      props.createDocument({
        RootFolderId: props.rootFolderId,
        SourceItemId: props.sourceItemId,
        ParentSourceId: props.parentSourceId,
        SourceType: props.sourceType,
        FolderId: props.folderId,
        FileIds: files.map((file: FileType) => file.Id),
        ...data,
      });
    } else {
      props.updateDocument({
        RootFolderId: props.rootFolderId,
        SourceItemId: props.sourceItemId,
        SourceType: props.sourceType,
        FolderId: props.folderId,
        DocumentId: props.document.Id,
        FileIds: files.map((file: FileType) => file.Id),
        RelatedSourceIds: props.document.RelatedSourceIds,
        ...data,
      });
    }
    setFiles([]);
    handleClose();
  };

  const handleClose = () => {
    if (uploading) return;
    reset();
    props.handleClose();
  };

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Name: props.document?.Name || "",
      Description: props.document?.Description || "",
      Permission: props.document?.Permission || PermissionTarget.Member,
    },
  });

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{!props.document ? t("Documents.CreateDocument") : t("General.Buttons.Edit")}</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <TextFieldConnector register={register("Name", { required: true })}>
            <TextField
              autoFocus
              label={t("Documents.Title")}
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.Name}
              style={{ marginBottom: 10 }}
            />
          </TextFieldConnector>
          <TextFieldConnector register={register("Description")}>
            <TextField label={t("Documents.Description")} variant="outlined" size="small" fullWidth />
          </TextFieldConnector>
          {props.usePermissions && (
            <FormControl fullWidth margin="dense" sx={{ "& .MuiSelect-select": { paddingTop: 1, paddingBottom: 1 } }}>
              <InputLabel id="permission-field-label">{t("Documents.Permission")}</InputLabel>
              <Controller
                name="Permission"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    label={t("Documents.Permission")}
                    labelId="permission-field-label"
                    error={!!errors.Permission}
                    onChange={(e) => {
                      setValue("Permission", e.target.value as PermissionTarget);
                    }}
                  >
                    {props.permissions.map((permission) => (
                      <MenuItem key={permission} value={permission} style={{ padding: "10px 20px" }}>
                        {t(`Documents.BuildingPermissions.${PermissionTarget[permission]}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="secondary" disabled={uploading}>
            {t("General.Buttons.Save")}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="secondary" disabled={uploading}>
            {t("General.Buttons.Undo")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DocumentDialog;
