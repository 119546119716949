import ApiService from "./api.service";
import {
  ServiceBookReport,
  ServiceBookOfferRequestContract,
  ServiceBookSearchResult,
  CraftsmanTaskList,
  ServiceBookReportPreview,
  CraftsmanTaskStatus,
  CraftsmanTaskListStatus,
  CustomerReportState,
  ServiceReportType,
  CreateTenderRequest,
  Tender,
  UpdateTenderRequest,
} from "../definitions/model/ServiceBook";
import { RegisterLeadContract } from "../definitions/LiteCapaign";
import { SearchResult } from "../definitions/model/SearchResult";
import { WorkerModel } from "../definitions/model/Worker";

export const ServiceBookService = {
  getUnitReports: async (houseId: string) => {
    return ApiService.get<ServiceBookReportPreview[]>("/api/Servicebook/reports/units/" + houseId);
  },
  getBuildingReports: async (buildingId: string, reportType: ServiceReportType) => {
    return ApiService.get<ServiceBookReportPreview[]>(
      `/api/Servicebook/reports/buildings/${buildingId}?reportType=${reportType}`
    );
  },
  getReport: async (reportId: string) => {
    return ApiService.get<ServiceBookReport>("/api/Servicebook/reports/" + reportId);
  },
  sendOfferRequest: async (data: ServiceBookOfferRequestContract) => {
    return ApiService.post("/api/Servicebook/sendOfferRequest", data);
  },

  acceptOffer: async (offerId: string) => {
    return ApiService.post("/api/Servicebook/acceptOffer?offerId=" + offerId, {});
  },

  rejectOffer: async (offerId: string, unitId: string) => {
    const url = `$/api/Servicebook/rejectOffer?offerId=${offerId}&unitId=${unitId}`;
    return ApiService.post(url, {});
  },

  getTasks: async (unitId: string, reportId?: string) => {
    let url = "/api/workers/getTasks/" + unitId;
    if (reportId) {
      url += `?reportId=${reportId}`;
    }

    return ApiService.get<ServiceBookSearchResult<CraftsmanTaskList>>(url);
  },

  setTaskStatus: async (taskId: string, unitId: string, status: CraftsmanTaskStatus) => {
    return ApiService.post("/api/workers/setTaskStatus/", {
      taskId,
      status,
      unitId,
    });
  },
  setTaskListStatus: async (tasklistId: string, status: CraftsmanTaskListStatus) => {
    return ApiService.post("/api/workers/setTaskListStatus", {
      tasklistId,
      status,
    });
  },
  sendReportRequest: async (data: RegisterLeadContract) => {
    return ApiService.post("/api/servicebook/sendReportRequest", data);
  },
  getCustomerReportState: async (reportId: string) => {
    return ApiService.get<CustomerReportState>("/api/servicebook/customerState/" + reportId);
  },
  setCustomerReportState: async (state: CustomerReportState) => {
    return ApiService.post("/api/servicebook/customerState/", state);
  },
  getLatestReport: async (unitId: string) => {
    return ApiService.get<{ ReportId: string; Consent: boolean }>("/api/servicebook/latest/" + unitId);
  },
  requestConsent: async (reportId: string) => {
    return ApiService.post("/api/servicebook/requestConsent/" + reportId, null);
  },
  manageConsent: async (reportId: string, managerId: string, given: boolean) => {
    return ApiService.post(`/api/servicebook/manageConsent/${reportId}?managerId=${managerId}&given=${given}`, null);
  },
  createTender: async (request: CreateTenderRequest) => {
    return ApiService.post<Tender>("/api/servicebook/tenders", request);
  },
  updateTender: async (request: UpdateTenderRequest) => {
    return ApiService.put<Tender>("/api/servicebook/tenders/" + request.TenderId, request);
  },
  getTenders: async (propertyId: string) => {
    return ApiService.get<SearchResult<Tender>>("/api/servicebook/tenders?propertyId=" + propertyId);
  },
  getTenderDetails: async (tenderId: string) => {
    return ApiService.get<Tender>("/api/servicebook/tenders/" + tenderId);
  },
  linkTenderContractor: async (tenderId: string, contractorId: string) => {
    return ApiService.post<Tender>(`/api/servicebook/tenders/${tenderId}/contractors/${contractorId}`, null);
  },
  unlinkTenderContractor: async (tenderId: string, contractorId: string) => {
    return ApiService.delete<Tender>(`/api/servicebook/tenders/${tenderId}/contractors/${contractorId}`, null);
  },
  getTenderContractors: async (tenderId: string) => {
    return ApiService.get<WorkerModel[]>(`/api/servicebook/tenders/${tenderId}/contractors`);
  }
};
