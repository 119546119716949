import React from "react";
import WorkerComponent from "../../../Workers/components/worker-component";
import { MCard } from "../../../../components/Base";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowDropDown, Search } from "@mui/icons-material";
import { useDefaultReducer } from "../../../../helpers/hooks";
import { CraftsmanType } from "../../../../definitions/model/Worker";
import { useServicebookWorker } from "../../../Workers/workers.actions";
import WorkerHelper from "../../../../helpers/worker-helper";
import { Building } from "../../../../definitions/model/Building";
import DetailsSkeleton from "../../../../components/Base/DetailsSkeleton";
import { useServicebook } from "../../../../actions/servicebook.actions";
import ContractorsCases from "./ContractorsCases";

type TenderContractorsPageState = {
  workerType: number;
  searchString: string;
  isCreateOpened: boolean;
  isConfirmOpened: boolean;
  isWorkerDialogShown: boolean;
  isCompanyDialogShown: boolean;
  workerToLink: string | undefined;
  linking: boolean;
  totalWorkers: number;
  findContractor: boolean;
  loadingContractors: boolean;
};

const initialState: TenderContractorsPageState = {
  workerType: 0,
  searchString: "",
  isCreateOpened: false,
  isConfirmOpened: false,
  isWorkerDialogShown: false,
  isCompanyDialogShown: false,
  workerToLink: undefined,
  linking: false,
  totalWorkers: 0,
  findContractor: false,
  loadingContractors: false,
};

type ContractorsComponentProps = {
  building: Building;
  tenderId: string;
  loading: boolean;
};

const ContractorsComponent = (props: ContractorsComponentProps) => {
  const { t } = useTranslation("translation");
  const [state, dispatch] = useDefaultReducer(initialState);
  const pageAmount = 10;

  const [{ loading, workers, error }, { getWorkers }] = useServicebookWorker();
  const [
    {
      tenderData: { contractors },
    },
    { linkTenderContractor, unlinkTenderContractor, getTenderContractors },
  ] = useServicebook();

  const searchStringChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const workerTypeChange = (event: SelectChangeEvent<number>) => {
    dispatch({ type: "workerType", payload: event.target.value as number });
  };

  React.useEffect(() => {
    const getAsync = async () => {
      if (props.building) {
        await getWorkers(
          {
            SearchString: state.searchString,
            FilterByType: state.workerType !== 0,
            PostCode: props.building.Address.PostalCode,
            CommuneCode: [props.building.Address.Commune.CommuneNumber],
            Types: state.workerType !== 0 ? [state.workerType] : WorkerHelper.GetWorkerTypes(state.searchString),
          },
          0,
          pageAmount
        );
      }
    };
    getAsync();
  }, [props.building, state.workerType, state.searchString]);

  const linkContractor = async (contractorId: string) => {
    try {
      if (props.building) {
        await linkTenderContractor(props.tenderId, contractorId);
        await getTenderContractors(props.tenderId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: "workerToLink", payload: undefined });
    }
  };

  const unlinkContractor = async (contractorId: string) => {
    try {
      if (props.building) {
        await unlinkTenderContractor(props.tenderId, contractorId);
        await getTenderContractors(props.tenderId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="secondary"
                checked={state.findContractor}
                onChange={(e) => dispatch({ type: "findContractor", payload: e.target.checked })}
              />
            }
            label={t("ServiceBook.Tender.FindContractors")}
            labelPlacement="start"
            // sx={{ marginRight: 0, "& .MuiTypography-root": { color: "white" } }}
          />
        </FormControl>
      </Grid>
      {state.findContractor && (
        <>
          <Grid item xs={12}>
            <SearchField
              label={t("ServiceBook.Tender.FindContractors")}
              variant="outlined"
              fullWidth
              value={state.searchString}
              onChange={searchStringChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth sx={{ margin: "10px 0" }} size="small">
              <InputLabel id="worker-type-label">{t("ServiceBook.Workers.FilterWorker")}</InputLabel>
              <Select
                labelId="worker-type-label"
                variant="outlined"
                sx={{
                  paddingTop: "2px",
                  paddingBottom: "1px",
                }}
                label={t("ServiceBook.Workers.FilterWorker")}
                value={state.workerType}
                onChange={workerTypeChange}
                IconComponent={(props) => <ArrowDropDown fontSize="large" {...props} />}
              >
                <StyledMenuItem value={0}>
                  <em>{t("ServiceBook.ReportList.No")}</em>
                </StyledMenuItem>
                {CraftsmanType.filter((type) => type.key !== 200)
                  .map((type) => ({
                    key: type.key,
                    value: t(type.value),
                  }))
                  .sort((a, b) => {
                    if (a.key == 100) return 1;
                    return a.value > b.value ? 1 : -1;
                  })
                  .map((type) => (
                    <StyledMenuItem key={type.key} value={type.key}>
                      {type.value}
                    </StyledMenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {loading && !workers.length ? (
            <List disablePadding>
              {new Array(10).fill(0).map((_, idx) => (
                <DetailsSkeleton key={idx} />
              ))}
            </List>
          ) : workers.length ? (
            <MCard type="main" title={t("ServiceBook.Tender.FindContractors")}>
              <List disablePadding>
                {workers.map((w, i) => (
                  <WorkerComponent
                    key={"worker-" + w.Id + i}
                    worker={w}
                    linked={contractors.some((c) => c.Id === w.Id)}
                    showControls={true}
                    error={error}
                    linkWorkerToUnit={linkContractor}
                    unlinkWorkerFromUnit={unlinkContractor}
                    linking={w.Id === state.workerToLink && state.linking}
                    linkLabel={t("ServiceBook.Tender.LinkContractorToTender")}
                  />
                ))}
              </List>
            </MCard>
          ) : (
            state.isWorkerDialogShown &&
            !!state.searchString && <Box mt={2.25}>{t("ServiceBook.Workers.WorkerNotFound")}</Box>
          )}
        </>
      )}

      {!state.findContractor && (
        <>
          {props.loading && state.loadingContractors && !contractors.length ? (
            <List disablePadding sx={{ width: "100%" }}>
              {new Array(10).fill(0).map((_, idx) => (
                <DetailsSkeleton key={idx} />
              ))}
            </List>
          ) : (
            <>
              <ContractorsCases building={props.building} tenderId={props.tenderId} loading={loading} />
              <MCard type="main" title={t("ServiceBook.Tender.Contractors")} sx={{ marginTop: 2, width: "100%" }}>
                <List disablePadding>
                  {contractors.map((w, i) => (
                    <WorkerComponent
                      key={"worker-" + w.Id + i}
                      worker={w}
                      linked={true}
                      showControls={true}
                      error={error}
                      linkWorkerToUnit={(contractorId) => Promise.resolve(undefined)}
                      unlinkWorkerFromUnit={unlinkContractor}
                      linking={w.Id === state.workerToLink && state.linking}
                      linkLabel={t("ServiceBook.Tender.UnlinkContractorFromTender")}
                    />
                  ))}
                </List>
              </MCard>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const SearchField = styled(TextField)(() => ({
  marginTop: 15,
  "& input": {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  paddingLeft: 10,
  paddingRight: 10,
}));

export default ContractorsComponent;
