import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { MCard } from "../../../../components/Base";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import { repeatSkeleton } from "../../../../components/Base/DetailsSkeleton";
import { Control, FieldErrors, useFieldArray, useForm, UseFormRegister } from "react-hook-form";
import { QualityParameter } from "../../../../definitions/model/ServiceBook";
import { Add, Delete, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import LabeldCard from "../../../../components/Base/LabeldCard";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconFilled": {
    color: theme.palette.primary.main,
  },
  "& .MuiRating-iconHover": {
    color: theme.palette.primary.light,
  },
}));

const StyledForm = styled("form")(() => ({
  width: "100%",
}));

type QualityParametersProps = {
  tenderId: string;
};

const QualityParameters = (props: QualityParametersProps) => {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = React.useState(false);
  const [paramenters, setParameters] = React.useState<QualityParameter | null>(null);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<QualityParameter>({
    defaultValues: {
      CorrectsErrorsWithoutCharge: true,
      DisposesOfWaste: false,
      FollowsAB18: false,
      Guarantee: false,
      HasCollectiveAgreement: false,
      MonthlyProgressReport: false,
      OperationAndMaintenance: "",
      PaymentTerms: false,
      QualityAssurance: false,
      SpeaksDanish: false,
    },
  });

  React.useEffect(() => {
    register("CorrectsErrorsWithoutCharge");
    register("SpeaksDanish");
    register("HasCollectiveAgreement");
    register("DisposesOfWaste");
    register("Guarantee");
    register("MonthlyProgressReport");
    register("PaymentTerms");
    register("QualityAssurance");
    register("FollowsAB18");
    register("CompatibilityWithConstructionManager");
  }, []);

  //load data
  React.useEffect(() => {
    setValue("CorrectsErrorsWithoutCharge", true);
    setParameters({
      Id: "",
      TenderId: "",
      ContractorId: "",
      SpeaksDanish: false,
      CorrectsErrorsWithoutCharge: true,
      InsuranceTypesCheck: [],
      HasCollectiveAgreement: false,
      AgreementsWithUnions: [],
      HourlyWageRates: [],
      Verification: [],
      CompatibilityWithConstructionManager: 0,
      DisposesOfWaste: false,
      Guarantee: false,
      MonthlyProgressReport: false,
      PaymentTerms: false,
      QualityAssurance: false,
      OperationAndMaintenance: "",
      ScheduleAndPenalties: [],
      FollowsAB18: false,
    });
  }, []);

  const save = async (data: QualityParameter) => {
    console.log(data);
  };

  return (
    <MCard
      type="main"
      title={
        props.tenderId
          ? t("ServiceBook.QualityParameters.EditQualityParameters")
          : t("ServiceBook.QualityParameters.CreateNewQualityParameters")
      }
      sx={{ width: "inherit" }}
    >
      <CardContent>
        <StyledForm onSubmit={handleSubmit(save)}>
          {loading && <>{repeatSkeleton({ width: "100%", height: 80 }, 10)}</>}
          {!loading && (
            <Grid container spacing={1} mb={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e) => setValue("CorrectsErrorsWithoutCharge", e.target.checked)}
                    />
                  }
                  label={t("ServiceBook.QualityParameters.CorrectsErrorsWithoutCharge")}
                  // checked={paramenters?.CorrectsErrorsWithoutCharge ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={(e) => setValue("SpeaksDanish", e.target.checked)} />}
                  label={t("ServiceBook.QualityParameters.SpeaksDanish")}
                  // checked={paramenters?.SpeaksDanish ?? false}
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.QualityParameters.InsuranceTypesCheck")}>
                  <Grid container spacing={1}>
                    <BooleanProperties
                      control={control}
                      register={register}
                      errors={errors}
                      addLabel={t("ServiceBook.QualityParameters.AddInsurance")}
                      nameFieldLabel={t("ServiceBook.QualityParameters.InsuranceName")}
                      name="InsuranceTypesCheck"
                    />
                  </Grid>
                </LabeldCard>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.QualityParameters.AgreementsWithUnions")}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e) => {
                              setValue("HasCollectiveAgreement", e.target.checked);
                            }}
                          />
                        }
                        label={t("ServiceBook.QualityParameters.HasCollectiveAgreement")}
                        // checked={paramenters?.HasCollectiveAgreement ?? false}
                      />
                    </Grid>
                    {watch("HasCollectiveAgreement") === true && (
                      <BooleanProperties
                        control={control}
                        register={register}
                        errors={errors}
                        addLabel={t("ServiceBook.QualityParameters.AddUnion")}
                        nameFieldLabel={t("ServiceBook.QualityParameters.UnionName")}
                        name="AgreementsWithUnions"
                      />
                    )}
                  </Grid>
                </LabeldCard>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.QualityParameters.HourlyWageRates")}>
                  <Grid container spacing={1}>
                    <NumericProperties
                      control={control}
                      register={register}
                      errors={errors}
                      addLabel={t("ServiceBook.QualityParameters.AddHourlyWageRate")}
                      nameFieldLabel={t("ServiceBook.QualityParameters.HourlyWageName")}
                      numberFieldLabel={t("ServiceBook.QualityParameters.HourlyWageValue")}
                      name="HourlyWageRates"
                    />
                  </Grid>
                </LabeldCard>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.QualityParameters.Verification")}>
                  <Grid container spacing={1}>
                    <BooleanProperties
                      control={control}
                      register={register}
                      errors={errors}
                      addLabel={t("ServiceBook.QualityParameters.AddVerification")}
                      nameFieldLabel={t("ServiceBook.QualityParameters.VerificationName")}
                      name="Verification"
                    />
                  </Grid>
                </LabeldCard>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={(e) => setValue("DisposesOfWaste", e.target.checked)} />}
                  label={t("ServiceBook.QualityParameters.DisposesOfWaste")}
                  // checked={paramenters?.DisposesOfWaste ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={(e) => setValue("Guarantee", e.target.checked)} />}
                  label={t("ServiceBook.QualityParameters.Guarantee")}
                  // checked={paramenters?.Guarantee ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox color="primary" onChange={(e) => setValue("MonthlyProgressReport", e.target.checked)} />
                  }
                  label={t("ServiceBook.QualityParameters.MonthlyProgressReport")}
                  // checked={paramenters?.MonthlyProgressReport ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={(e) => setValue("PaymentTerms", e.target.checked)} />}
                  label={t("ServiceBook.QualityParameters.PaymentTerms")}
                  // checked={paramenters?.PaymentTerms ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox color="primary" onChange={(e) => setValue("QualityAssurance", e.target.checked)} />
                  }
                  label={t("ServiceBook.QualityParameters.QualityAssurance")}
                  // checked={paramenters?.QualityAssurance ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldConnector register={register("OperationAndMaintenance")}>
                  <TextField
                    label={t("ServiceBook.QualityParameters.OperationAndMaintenance")}
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    fullWidth
                    variant="outlined"
                    error={!!errors.OperationAndMaintenance}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.QualityParameters.ScheduleAndPenalties")}>
                  <Grid container spacing={1}>
                    <NumericProperties
                      control={control}
                      register={register}
                      errors={errors}
                      addLabel={t("ServiceBook.QualityParameters.AddPenalty")}
                      nameFieldLabel={t("ServiceBook.QualityParameters.PenaltyName")}
                      numberFieldLabel={t("ServiceBook.QualityParameters.PenaltyValue")}
                      name="ScheduleAndPenalties"
                    />
                  </Grid>
                </LabeldCard>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={(e) => setValue("FollowsAB18", e.target.checked)} />}
                  label={t("ServiceBook.QualityParameters.FollowsAB18")}
                  // checked={paramenters?.FollowsAB18 ?? false}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">
                  {t("ServiceBook.QualityParameters.CompatibilityWithConstructionManager")}
                </Typography>
                <StyledRating
                  name="CompatibilityWithConstructionManager"
                  color="secondary"
                  max={10}
                  precision={0.5}
                  icon={<RadioButtonChecked fontSize="inherit" />}
                  emptyIcon={<RadioButtonUnchecked fontSize="inherit" />}
                  // value={value}
                  onChange={(event, newValue) => {
                    setValue("CompatibilityWithConstructionManager", newValue ?? 0);
                  }}
                />
              </Grid>
            </Grid>
          )}
          <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
            {t("General.Buttons.Save")}
          </LoadingButton>
        </StyledForm>
      </CardContent>
    </MCard>
  );
};

export default QualityParameters;

type BooleanPropertiesProps = {
  control: Control<QualityParameter, any>;
  register: UseFormRegister<QualityParameter>;
  errors: FieldErrors<QualityParameter>;
  name: "InsuranceTypesCheck" | "AgreementsWithUnions" | "Verification";
  addLabel: string;
  nameFieldLabel: string;
};
const BooleanProperties = ({ control, register, errors, name, addLabel, nameFieldLabel }: BooleanPropertiesProps) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: name,
  });

  return (
    <>
      {fields.map((prop, propIndex) => (
        <Grid container item key={`insurance-${propIndex}`}>
          <Grid item xs={10}>
            <TextFieldConnector register={register(`${name}.${propIndex}.Name`)}>
              <TextField
                label={nameFieldLabel}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                error={!!errors[name]?.[propIndex]?.Name}
              />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="large"
                  onChange={(e) => {
                    const data = { ...prop, Verified: e.target.checked };
                    update(propIndex, data);
                  }}
                />
              }
              label=""
              checked={prop.Verified ?? false}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => remove(propIndex)} color="default" size="large">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Add />}
          onClick={() => {
            append({
              Id: uuid(),
              Name: "",
              Verified: false,
            });
          }}
        >
          {addLabel}
        </Button>
      </Grid>
    </>
  );
};

type NumericPropertiesProps = {
  control: Control<QualityParameter, any>;
  register: UseFormRegister<QualityParameter>;
  errors: FieldErrors<QualityParameter>;
  name: "HourlyWageRates" | "ScheduleAndPenalties";
  addLabel: string;
  nameFieldLabel: string;
  numberFieldLabel: string;
};
const NumericProperties = ({
  control,
  register,
  errors,
  name,
  addLabel,
  nameFieldLabel,
  numberFieldLabel,
}: NumericPropertiesProps) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: name,
  });

  return (
    <>
      {fields.map((prop, propIndex) => (
        <Grid container item key={`insurance-${propIndex}`} spacing={1}>
          <Grid item xs={8}>
            <TextFieldConnector register={register(`${name}.${propIndex}.Name`)}>
              <TextField
                label={nameFieldLabel}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                error={!!errors[name]?.[propIndex]?.Name}
              />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={3}>
            <TextFieldConnector register={register(`${name}.${propIndex}.Name`)}>
              <TextField
                label={numberFieldLabel}
                InputLabelProps={{ shrink: true }}
                type="number"
                fullWidth
                variant="outlined"
                error={!!errors[name]?.[propIndex]?.Name}
              />
            </TextFieldConnector>
            {/* <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="large"
                  onChange={(e) => {
                    const data = { ...prop, Verified: e.target.checked };
                    update(propIndex, data);
                  }}
                />
              }
              label=""
              checked={prop.Verified ?? false}
            /> */}
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => remove(propIndex)} color="default" size="large">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Add />}
          onClick={() => {
            append({
              Id: uuid(),
              Name: "",
              NumberValue: 0,
            });
          }}
        >
          {addLabel}
        </Button>
      </Grid>
    </>
  );
};
