import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import BuildingMembersList from "./BuildingMembersList";
import { LoadWrapper, MCard, useAlertContext } from "../../../components/Base";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../../../actions/building.actions";
import UnitHelper from "../../../helpers/unit-helper";
import { ArrowBack, PersonAdd } from "@mui/icons-material";
import { GroupMemberRole } from "../../../constants/enums";
import EditBuildingMemberDialog from "./EditBuildingMemberDialog";

const BuildingMembersPage = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const alert = useAlertContext();
  const { id } = useParams<{ id: string }>();
  const [{ loading, groupMembers, error, building }, buildingActions] = useBuilding();
  const memberRoles = [
    GroupMemberRole.Owner,
    GroupMemberRole.Administrator,
    GroupMemberRole.Manager,
    GroupMemberRole.Caretacker,
    GroupMemberRole.Architect,
    GroupMemberRole.Member,
    GroupMemberRole.Expert,
  ];
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  useEffect(() => {
    if (id) {
      if (building?.Id !== id) {
        buildingActions.getBuilding(id);
      }
      if (!groupMembers || groupMembers.BuildingId !== id) {
        buildingActions.getBuildingMembers(id);
      }
    }
  }, [id]);

  useEffect(() => {
    if (error) alert.showAlert({ text: error, severity: "error" });
  }, [error]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (window.history.state.idx !== 0) {
            navigate(-1);
            return;
          }

          navigate("/buildings");
        }}
        type="submit"
        sx={{ marginBottom: "10px" }}
      >
        <ArrowBack fontSize="small" />
        {t("General.Buttons.Back")}
      </Button>
      <Grid container>
        <Grid item container direction="column">
          <LoadWrapper isLoading={loading} result={groupMembers}>
            {groupMembers && (
              <>
                <MCard
                  key={groupMembers.BuildingId}
                  type="main"
                  title={UnitHelper.GetFullAddress(groupMembers.Address)}
                  headerAction={
                    <Button
                      color="secondary"
                      size="small"
                      variant="outlined"
                      sx={{ borderRadius: "50%", minWidth: "unset", padding: 1, margin: 0 }}
                      onClick={() => setOpenEditDialog(true)}
                    >
                      <PersonAdd />
                    </Button>
                  }
                >
                  <BuildingMembersList
                    members={groupMembers.Members}
                    pendingMembers={groupMembers.PendingMembers}
                    memberRoles={memberRoles}
                    buildingId={groupMembers.BuildingId}
                    onChangeRoleAction={buildingActions.changeMemberRole}
                  />
                </MCard>
                {id && (
                  <EditBuildingMemberDialog
                    buildingId={id}
                    members={groupMembers.Members}
                    pendingMembers={groupMembers.PendingMembers}
                    memberRoles={memberRoles}
                    open={openEditDialog}
                    handleClose={() => setOpenEditDialog(false)}
                    addMemberMode={true}
                  />
                )}
              </>
            )}
          </LoadWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default BuildingMembersPage;
