import { UserConstants, UnitConstants } from "../constants";
import UserService from "../services/user.service";
import User, {
  CompleteRegistrationData,
  UserProfileDTO,
  UserRating,
  UserRegistrationDTO,
} from "../definitions/model/User";
import { AppThunkDispatch } from "../definitions/Action";
import UnitService from "../services/unit.service";
import { UserInterest } from "../constants/enums";
import Unit from "../definitions/model/unit/Unit";
import { DocumentActions } from "../constants/document.actiontypes";
import { getOwnedHouses } from "./unit.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from "../reducers/store";
import { getNavigationItems } from "../pages/Overview/navigation.actions";
import { assignNewFamilyOwner, getUserFamilies, useFamily } from "./family.actions";
import { getActiveBehaviourRules } from "./behaviourRules.actions";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { LanguageType } from "../definitions/Menu";
import { GroupMemberDto } from "../definitions/model/Group";
import { useDocument } from "./document.actions";
import { useSelector } from "react-redux";
import { loadPlan } from "../pages/ClimatePlan/shared/co2plan.actions";
import { DocumentStoreState } from "../reducers/document.reducer";
import { FamilyStoreState } from "../reducers/family.reducer";
import { DocumentSourceType } from "../definitions/Document";

const operationFailedAction = (payload: unknown) =>
  operationFailedActionGeneral(payload, UserConstants.USER_OPERATION_FAILED);

export const _getBasicData =
  (user: User | null = null) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({ type: UserConstants.GET_USER });
    const userData = await UserService.getCurrentServerUser();
    user = userData?.User ?? user;

    let unit: Unit | null = null;
    if (userData?.User) {
      if (userData?.Unit) {
        unit = userData.Unit;
        dispatch({
          type: UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED,
          payload: userData.Unit,
        });
        dispatch({
          type: DocumentActions.GET_FOLDERS_SUCCEDED,
          payload: {
            result: { Folders: userData.Unit.DocumentFolders ?? [], RootFolderId: userData.Unit.DocumentRootFolderId },
            SourceType: DocumentSourceType.Unit,
          },
        });
      }

      if (userData?.PendingUnit) {
        unit = userData.PendingUnit;
        dispatch({
          type: UnitConstants.GET_PENDING_PROPERTY_SUCCEEDED,
          payload: userData?.PendingUnit,
        });
      }

      dispatch({
        type: UserConstants.GET_USER_SUCCEEDED,
        payload: { User: userData.User, MainUser: userData.MainUser },
      });

      //pre-load co2Plan to use in conditional widgets
      if (unit && unit.HasCO2Plan) {
        await loadPlan(unit.Id);
      }
    }

    // await dispatch(getUserSubscriptions());
    await dispatch(getNavigationItems(user?.Rating));
    await getActiveBehaviourRules();

    return unit;
  };

export const authorize = (username: string, password: string) => async (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.LOGIN_REQUEST, payload: { username } });

  try {
    const user = await UserService.login(username, password);

    dispatch({ type: UserConstants.LOGIN_SUCCESS, payload: { User: user } });

    const unit = await dispatch(_getBasicData(user));

    dispatch({ type: UserConstants.START_SIGNALR });
    return [user, unit];
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const authorizeFacebook = (facebookId: string) => async (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.OPEN_FACEBOOK_LOGIN });
  try {
    const user = await UserService.authenticateFacebookUser(facebookId);

    dispatch({
      type: UserConstants.LOGIN_SUCCESS,
      payload: { User: user },
    });

    const unit = await dispatch(_getBasicData(user));

    dispatch({ type: UserConstants.START_SIGNALR });

    return [user, unit];
  } catch (error: any) {
    if (error.status === 401) dispatch(operationFailedAction(new Error("Authorization.Errors.FacebookNotFound")));
    else dispatch(operationFailedAction(error));
    throw error;
  }
};

export const register = (userData: UserRegistrationDTO) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: UserConstants.REGISTER_REQUEST,
      payload: { username: userData.Email },
    });

    if (!userData.Name && userData.Email) {
      userData.Name = userData.Email.split("@")[0];
    }

    const user = await UserService.register(userData);
    if (user.NewUser) dispatch({ type: UserConstants.REGISTER_SUCCESS, payload: { User: user } });
    else dispatch({ type: UserConstants.LOGIN_SUCCESS, payload: { User: user } });

    return user;
  } catch (error) {
    // dispatch(operationFailedAction(error));
    console.log(error);
    throw error;
  }
};

const openLoginDialog =
  (loginAndStay: boolean, loginOnly?: boolean, loginButtonText?: string, employerId?: string, communeNumber?: number) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: UserConstants.OPEN_LOGIN_DIALOG,
      payload: { loginAndStay, loginOnly, loginButtonText, employerId, communeNumber },
    });
  };

const setAuthDestination =
  (
    authSimpleRegistration: boolean,
    authDestination: string,
    authUserRating: UserRating,
    registerExtraStep: string | null = null
  ) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: UserConstants.SET_AUTH_DESTINATION,
      payload: { authSimpleRegistration, authDestination, authUserRating, registerExtraStep },
    });
  };

const openRegisterDialog =
  (authPropertyId?: string, employerId?: string, communeNumber?: number) => async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: UserConstants.OPEN_CREATE_DIALOG,
      payload: { authPropertyId, employerId, communeNumber },
    });
  };

export const logout = () => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.STOP_SIGNALR });
    dispatch({ type: UserConstants.LOGOUT });
    UserService.logout();
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const trackInitiateMoveIn = () => (dispatch: AppThunkDispatch) => {
  dispatch({
    type: UserConstants.INITIATE_MOVE_IN,
  });
};

export const moveInToProperty =
  (unitId: string, ignoreNotifications: boolean) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: UserConstants.MOVE_IN_UNIT,
      });
      const user = await UserService.moveInToProperty(unitId, ignoreNotifications);
      dispatch({
        type: UserConstants.MOVE_IN_UNIT_SUCCESS,
        payload: user,
      });

      let unit = await UnitService.movedInProperty();
      if (unit)
        dispatch({
          type: UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED,
          payload: unit,
        });

      if (!unit) {
        unit = await UnitService.getPendingMoveInProperty();
        dispatch({
          type: UnitConstants.GET_PENDING_PROPERTY_SUCCEEDED,
          payload: unit,
        });
      }

      return unit;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const startSignalR = () => (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.START_SIGNALR });
};

const setPwaOptions = (pwaOptions: any) => (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.SET_PWA_OPTIONS, payload: pwaOptions });
};

export const setUserTheme = (themeName: string | null) => async (dispatch: AppThunkDispatch) => {
  try {
    await UserService.setUserTheme(themeName);

    dispatch({
      type: UserConstants.GET_USER_THEME_SUCCEEDED,
      payload: themeName,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getUserTheme = () => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.GET_USER_THEME });

    const themeName = await UserService.getUserTheme();
    dispatch({
      type: UserConstants.GET_USER_THEME_SUCCEEDED,
      payload: themeName,
    });

    return themeName;
  } catch (error) {
    dispatch(operationFailedAction(error));
    return null;
  }
};

export const completeRegistration = (userData: CompleteRegistrationData) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.COMPLETE_REGISTRATION });
    const user = await UserService.completeRegistration(userData);
    dispatch({
      type: UserConstants.COMPLETE_REGISTRATION_SUCCESS,
      payload: { User: user },
    });

    await dispatch(_getBasicData(user));
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const leaveAllCommunes = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.LEAVE_ALL_COMMUNES });
    const result = await UserService.leaveAllCommunes(id);
    dispatch({
      type: UserConstants.LEAVE_ALL_COMMUNES_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateUserInterests = (interests: UserInterest[]) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.UPDATE_USER_INTERESTS });
    const result = await UserService.updateUserInterests(interests);
    dispatch({
      type: UserConstants.UPDATE_USER_INTERESTS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateUserPicture = (picture: File) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.UPDATE_USER_PICTURE });
    const result = await UserService.updateUserPicture(picture);
    dispatch({
      type: UserConstants.UPDATE_USER_PICTURE_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const clearUserError = () => (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.USER_OPERATION_FAILED, payload: null });
};

export const removeUser = () => async (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.REMOVE_USER });
  try {
    await UserService.removeUser();
    dispatch({ type: UserConstants.REMOVE_USER_SUCCEEDED });
    UserService.logout();
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateUserProfile =
  (userProfileData: UserProfileDTO) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.UPDATE_USER_PROFILE });
    try {
      const result = await UserService.updateUserProfile(userProfileData);

      UserService.updateLocalStorageUser(result);
      dispatch({
        type: UserConstants.UPDATE_USER_PROFILE_SUCCEEDED,
        payload: userProfileData,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateUserLanguage =
  (lang: LanguageType) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.UPDATE_USER_LANGUAGE });
    try {
      const result = await UserService.updateUserLanguage(lang);

      UserService.updateLocalStorageUser(result);
      dispatch({
        type: UserConstants.UPDATE_USER_LANGUAGE_SUCCEEDED,
        payload: result as UserProfileDTO,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateNotificationSettigns =
  (userId: string | null, sendNotifications: boolean, receiveCalls: boolean, sendFacebookNotifications: boolean) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({ type: UserConstants.UPDATE_USER_NOTIFICATION_SETTINGS });
    try {
      const result = await UserService.updateNotificationSettigns(
        sendNotifications,
        receiveCalls,
        sendFacebookNotifications
      );
      dispatch({
        type: UserConstants.UPDATE_USER_NOTIFICATION_SETTINGS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const changeUserPassword = (password: string) => async (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.CHANGE_USER_PASSWORD });
  try {
    const result = await UserService.changeUserPassword(password);
    dispatch({ type: UserConstants.CHANGE_USER_PASSWORD_SUCCEEDED });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getUserSettings =
  (signal?: AbortSignal) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.GET_USER_SETTIINGS });
    try {
      const result = await UserService.getUserSettings(signal);
      dispatch({
        type: UserConstants.GET_USER_SETTIINGS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getUserSettingsHistory = (signal?: AbortSignal) => async (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.GET_USER_SETTINGS_HISTORY });
  try {
    const result = await UserService.getUserSettingsHistory(signal);
    dispatch({
      type: UserConstants.GET_USER_SETTINGS_HISTORY_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getUserLogMessages =
  (userId: string, logSources: string[], signal?: AbortSignal) => async (dispatch: AppThunkDispatch) => {
    dispatch({ type: UserConstants.GET_USER_LOG_MESSAGES });
    try {
      const result = await UserService.getUserLogMessages(userId, logSources, signal);
      dispatch({
        type: UserConstants.GET_USER_LOG_MESSAGES_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const moveOutUnit = (unitId: string) => async (dispatch: AppThunkDispatch) => {
  dispatch({ type: UserConstants.MOVE_OUT_UNIT });
  try {
    const result = await UserService.moveOutUnit(unitId);
    UserService.updateLocalStorageUser(result.User);
    dispatch({
      type: UserConstants.MOVE_OUT_UNIT_SUCCESS,
      payload: {
        user: result.User,
        unit: result.Unit,
      },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateCurrentHouse = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: UserConstants.CHANGE_USER_CURRENT_HOUSE,
    });
    const result = await UserService.updateUserCurrentHouse(id);
    dispatch({
      type: UserConstants.CHANGE_USER_CURRENT_HOUSE_SUCCEEDED,
      payload: result,
    });

    dispatch({
      type: DocumentActions.GET_FOLDERS_SUCCEDED,
      payload: {
        result: { Folders: result?.Unit?.DocumentFolders ?? [], RootFolderId: result?.Unit?.DocumentRootFolderId },
        SourceType: DocumentSourceType.Unit,
      },
    });

    return result.Unit;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const updateCurrentBuilding = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: UserConstants.CHANGE_USER_CURRENT_BUILDING,
    });
    const result = await UserService.updateUserCurrentBuilding(id);
    dispatch({
      type: UserConstants.CHANGE_USER_CURRENT_BUILDING_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const subscribeToPhotosUpdate = (unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: UserConstants.SUBSCRIBE_TO_PHOTO_UPDATE,
    });
    await UserService.subscribeOnHousePhotosUpdate(unitId);
    dispatch({
      type: UserConstants.SUBSCRIBE_TO_PHOTO_UPDATE_SUCCEEDED,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const subscribeOnHousePriceChanges = (unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: UserConstants.SUBSCRIBE_TO_PRICE_UPDATE,
    });
    await UserService.subscribeOnHousePriceChanges(unitId);
    dispatch({
      type: UserConstants.SUBSCRIBE_TO_PRICE_UPDATE_SUCCEEDED,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const isUserSubscribedToHousePhotoUpdate = (unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: UserConstants.CHECK_IS_USER_SUBSCRIBED_TO_PHOTO_UPDATE,
    });
    const result = await UserService.isUserSubscribedToHousePhotoUpdate(unitId);
    dispatch({
      type: UserConstants.CHECK_IS_USER_SUBSCRIBED_TO_PHOTO_UPDATE_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const setLanguage = (language: LanguageType) => (dispatch: AppThunkDispatch) => {
  try {
    UserService.setLanguage(language);

    dispatch({
      type: UserConstants.SET_LANGUAGE,
      payload: language,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getLanguage = () => (dispatch: AppThunkDispatch) => {
  const lang = UserService.getLanguage();

  dispatch({
    type: UserConstants.GET_LANGUAGE,
    payload: lang,
  });
  return lang;
};

export const acceptNewTerms = () => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    await UserService.acceptNewTerms();

    dispatch({
      type: UserConstants.NEW_TERMS_ACCEPTED,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const checkIsFacebookUserExist = (fkey: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.CHECK_IS_FACEBOOK_USER_EXIST });
    const result = await UserService.isFacebookUserExist(fkey);
    dispatch({ type: UserConstants.CHECK_IS_FACEBOOK_USER_EXIST_SUCCEEDED });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const setUserPreferencesStyle = (style: string) => async (dispatch: AppThunkDispatch) => {
  try {
    const result = await UserService.setUserPreferencesStyle(style);
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const tryUser = (userId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.TRY_USER });
    const result = await UserService.tryUser(userId);
    dispatch({ type: UserConstants.TRY_USER_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const unTryUser = () => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: UserConstants.UNTRY_USER });
    UserService.unTryUser();
    dispatch({ type: UserConstants.UNTRY_USER_SUCCEEDED });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const setUiMode = (mode: "unit" | "building") => async (dispatch: AppThunkDispatch) => {
  dispatch({
    type: UserConstants.SET_UI_MODE,
    payload: mode,
  });
};

const dispatchMessage = (type: string, payload?: any) => (dispatch: AppThunkDispatch) => dispatch({ type, payload });

const useUserActions = () => {
  const dispatch = useAppDispatch();

  return {
    setUiMode: (mode: "unit" | "building") => dispatch(setUiMode(mode)),
    setPwaOptions: (pwaOptions: any) => dispatch(setPwaOptions(pwaOptions)),
    startSignalR: () => dispatch(startSignalR()),
    _getBasicData: () => dispatch(_getBasicData()),
    updateUserProfile: (userProfileData: UserProfileDTO) => dispatch(updateUserProfile(userProfileData)),
    updateUserLanguage: (lang: LanguageType) => dispatch(updateUserLanguage(lang)),
    changeUserPassword: (password: string) => dispatch(changeUserPassword(password)),
    authorize: (username: string, password: string) => dispatch(authorize(username, password)),
    moveInToProperty: (unitId: string, ignoreNotifications: boolean) =>
      dispatch(moveInToProperty(unitId, ignoreNotifications)),
    clearUserError: () => dispatch(clearUserError()),
    checkIsFacebookUserExist: (fkey: string) => dispatch(checkIsFacebookUserExist(fkey)),
    registerUser: (userData: UserRegistrationDTO) => dispatch(register(userData)),
    authorizeFacebook: (facebookId: string) => dispatch(authorizeFacebook(facebookId)),
    completeRegistration: (userData: CompleteRegistrationData) => dispatch(completeRegistration(userData)),
    setLanguage: (language: LanguageType) => dispatch(setLanguage(language)),
    getLanguage: () => dispatch(getLanguage()),
    logout: () => dispatch(logout()),
    acceptNewTerms: () => dispatch(acceptNewTerms()),
    updateUserInterests: (interests: UserInterest[]) => dispatch(updateUserInterests(interests)),
    removeUser: () => dispatch(removeUser()),
    getUserSettings: (signal?: AbortSignal) => dispatch(getUserSettings(signal)),
    updateNotificationSettigns: (
      userId: string | null,
      sendNotifications: boolean,
      receiveCalls: boolean,
      sendFacebookNotifications: boolean
    ) => dispatch(updateNotificationSettigns(null, sendNotifications, receiveCalls, sendFacebookNotifications)),
    // changeUserPassword: (password: string) =>
    //   dispatch(changeUserPassword(password)),
    getUserSettingsHistory: (signal?: AbortSignal) => dispatch(getUserSettingsHistory(signal)),
    getUserLogMessages: (userId: string, logSources: string[], signal?: AbortSignal) =>
      dispatch(getUserLogMessages(userId, logSources, signal)),
    updateUserPicture: (picture: File) => dispatch(updateUserPicture(picture)),
    moveOutUser: (unitId: string) => dispatch(moveOutUnit(unitId)),
    getUserFamilies: (unitId: string | null) => dispatch(getUserFamilies(unitId)),
    assignNewFamilyOwner: (newOwner: GroupMemberDto, unitId: string) =>
      dispatch(assignNewFamilyOwner(newOwner, unitId)),
    // cancelSubscription: (subscriptionId: string) =>
    //   dispatch(cancelUserSubscription(subscriptionId)),
    // getUserCards: () => dispatch(getUserCreditCards()),
    // changeDefaultCard: (token: string) => dispatch(changeDefaultCard(token)),
    // addUserCard: (token: string, email: string) =>
    //   dispatch(addUserCard(token, email)),
    // removeCard: (token: string) => dispatch(removeCreditCard(token)),
    getOwnedHouses: (signal?: AbortSignal) => dispatch(getOwnedHouses(signal)),
    changeCurrentHouse: (unitId: string) => dispatch(updateCurrentHouse(unitId)),
    changeCurrentBuilding: (buildingId: string) => dispatch(updateCurrentBuilding(buildingId)),
    setUserTheme: (themeName: string | null) => dispatch(setUserTheme(themeName)),
    leaveAllCommunes: (id: string) => dispatch(leaveAllCommunes(id)),
    openLoginDialog: (
      loginAndStay: boolean,
      loginOnly?: boolean,
      loginButtonText?: string,
      employerId?: string,
      communeNumber?: number
    ) => dispatch(openLoginDialog(loginAndStay, loginOnly, loginButtonText, employerId, communeNumber)),
    openRegisterDialog: (authPropertyId?: string, employerId?: string, communeNumber?: number) =>
      dispatch(openRegisterDialog(authPropertyId, employerId, communeNumber)),
    setAuthDestination: (
      authSimpleRegistration: boolean,
      authDestination: string,
      authUserRating: UserRating,
      registerExtraStep: string | null = null
    ) => dispatch(setAuthDestination(authSimpleRegistration, authDestination, authUserRating, registerExtraStep)),
    isUserSubscribedToHousePhotoUpdate: (unitId: string) => dispatch(isUserSubscribedToHousePhotoUpdate(unitId)),
    subscribeOnPhotoUpdate: (unitId: string) => dispatch(subscribeToPhotosUpdate(unitId)),
    subscribeOnHousePriceChanges: (unitId: string) => dispatch(subscribeOnHousePriceChanges(unitId)),
    trackInitiateMoveIn: () => dispatch(trackInitiateMoveIn()),
    setUserPreferencesStyle: (style: string) => dispatch(setUserPreferencesStyle(style)),
    tryUser: (userId: string) => dispatch(tryUser(userId)),
    unTryUser: () => dispatch(unTryUser()),
    dispatchMessage: (type: string, payload?: any) => dispatch(dispatchMessage(type, payload)),
  };
};

const useUserState = () => useSelector((state: ApplicationState) => state.user);

export const useUser = (): [
  ReturnType<typeof useUserState> & { document: DocumentStoreState; family: FamilyStoreState },
  ReturnType<typeof useUserActions>
] => {
  const state = useUserState();
  const [document] = useDocument();
  const [family] = useFamily();

  const actions = useUserActions();

  return [{ ...state, document, family }, actions];
};
